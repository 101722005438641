import { Icons } from '@agro-club/agroclub-shared'
import { BidTypes } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { getDealBidAndContract } from 'modules/domain/deal/utils'
import { FC } from 'react'
import { generatePath } from 'react-router'
import { AButton } from 'views/components/Analytics'
import BidRoutes from 'views/pages/Bid/routes'
import BidContractsRoutes from 'views/pages/BidContracts/routes'
import { DealPartyChangeData } from '../../../hooks/useDealPartyChange'
import { getOppositeBidType } from 'modules/domain/bid/utils'

import { AddBidContractAction } from './AddBidContractAction'
import { EditBidContractAction } from './EditBidContractAction'
import { ChangeDealPartyAction } from './ChangeDealPartyAction'

type BidDetailActionsProps = {
  deal: Deal
  bidType: BidTypes
  refetch: refetchFunc
  partyChanger: DealPartyChangeData
}

export const BidDetailActions: FC<BidDetailActionsProps> = ({ refetch, deal, bidType, partyChanger }) => {
  const { bid, contract } = getDealBidAndContract(deal, bidType)
  const bidContractLink = contract
    ? generatePath(BidContractsRoutes.Details, { id: contract.id })
    : generatePath(BidRoutes.Details, { id: bid?.id })

  const ourPartyChanging = partyChanger.bidType === bidType
  const otherPartyChanging = partyChanger.bidType === getOppositeBidType(bidType)

  if (ourPartyChanging) {
    return <ChangeDealPartyAction partyChanger={partyChanger} type="stop" />
  }

  return (
    <>
      <AddBidContractAction deal={deal} bidType={bidType} refetch={refetch} disabled={otherPartyChanging} />
      <ChangeDealPartyAction bidType={bidType} partyChanger={partyChanger} type="start" />
      <EditBidContractAction deal={deal} bidType={bidType} refetch={refetch} disabled={otherPartyChanging} />
      <AButton target="_blank" id="link" intent="white" Icon={Icons.IconOpen} to={bidContractLink} />
    </>
  )
}
