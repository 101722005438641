import { DocumentFile, DocumentType } from 'modules/domain/types'
import * as userManagers from 'modules/domain/user/managers'
import { AFilesByTypes } from 'views/components/FilesByTypes/AFilesByTypes'
import { FC, useEffect } from 'react'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { Progress } from '@agro-club/agroclub-shared'

type Props = {
  setSummary: (arg: number) => void
  ownerId: string
}

export const DealSellerDocuments: FC<Props> = ({ setSummary, ownerId }) => {
  const [, documentTypes = []] = useSingleEntity<DocumentType[]>(endpoints.userDocumentTypes(ownerId))
  const [progress, documents = [], documentsRefetch] = useSingleEntity<DocumentFile[]>(
    endpoints.userDocumentFiles(ownerId),
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) {
      setSummary(documents.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documents.length])

  const onUploadHandler = async (document_type, file) => {
    await userManagers.uploadDocuments(ownerId, document_type, file)
    documentsRefetch()
  }

  const onDeleteHandler = async (document_id) => {
    await userManagers.deleteDocumentFile(ownerId, document_id)
    documentsRefetch()
  }

  return <AFilesByTypes types={documentTypes} files={documents} onUpload={onUploadHandler} onDelete={onDeleteHandler} />
}
