import { FC } from 'react'
import { useFormik } from 'formik'

import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { useTranslation } from 'react-i18next'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { REGULAR_LABEL_GAP, REGULAR_LABEL_WIDTH } from 'views/pages/LogisticsKR/constants'
import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { removeSeconds } from 'views/pages/LogisticsKR/helpers'
import { CommonLoadWHForm } from 'views/pages/LogisticsKR/components/CommonLoadWHForm'
import { addPrefixInErrors, removePropertyNamePrefix } from 'modules/utils/helpers'
import {
  isUnloadingWarehouse,
  LogisticsKRLoadingWarehouse,
  LogisticsKRUnloadingWarehouse,
} from 'views/pages/LogisticsKR/types'
import ASharedModal from 'views/components/SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/LogisticsKR/analyticsPlaces'
import ADefaultModalFooter from 'views/components/DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'

interface Props {
  setIsOpen: (val: boolean) => void
  onSuccess: () => void
  warehouse: LogisticsKRLoadingWarehouse | LogisticsKRUnloadingWarehouse
}

export const EditLoadingModal: FC<Props> = ({ setIsOpen, onSuccess, warehouse }) => {
  const { formProgress, formHandler } = useAFormHandler()
  const { t } = useTranslation('logisticsKR')

  const formik = useFormik({
    initialValues: {
      loading_warehouse_id: warehouse?.id,
      loading_warehouse_address: warehouse?.address,
      loading_warehouse_latitude: warehouse?.latitude,
      loading_warehouse_longitude: warehouse?.longitude,
      loading_warehouse_geo_object: warehouse?.geo_object,
      loading_warehouse_gates_height: warehouse?.gates_height,
      loading_warehouse_loader_type: !isUnloadingWarehouse(warehouse) && warehouse?.loader_type,
      loading_warehouse_scales_capacity: warehouse?.scales_capacity,
      loading_warehouse_scales_remoteness: warehouse?.scales_remoteness,
      loading_warehouse_scales_length: warehouse?.scales_length,
      loading_warehouse_volume_per_day: warehouse?.volume_per_day,
      loading_warehouse_vehicle_type: warehouse?.vehicle_type,
      loading_warehouse_schedule_from: removeSeconds(warehouse?.schedule_from),
      loading_warehouse_schedule_to: removeSeconds(warehouse?.schedule_to),
      loading_warehouse_works_on_weekend: warehouse?.works_on_weekend,
      loading_warehouse_car_delivery: warehouse?.car_delivery,
      loading_warehouse_railway_delivery: warehouse?.railway_delivery,
    },
    onSubmit: formHandler(
      async () =>
        await apiClient.put(
          endpoints.LogisticsKR.loadingWarehouse(warehouse.id),
          removePropertyNamePrefix(formik.values, /loading_warehouse_/g),
        ),
      {
        onSuccess: () => {
          onSuccess()
          setIsOpen(false)
        },
        onError: (error) => {
          const { errors } = error
          formik.setErrors(addPrefixInErrors(errors, 'loading_warehouse'))
        },
      },
    ),
  })

  return (
    <ASharedModal id={AnalyticsPlaces.WAREHOUSES.EDIT_MODAL} size="large" onClose={() => setIsOpen(false)}>
      <SharedModalTitle>{t('warehouses.forms.editHeading')}</SharedModalTitle>

      <SharedModalContent>
        <SharedModalInputs>
          <FormGroup
            error={formik.errors.loading_warehouse_latitude || formik.errors.loading_warehouse_longitude}
            label={t('list.createForm.labels.address')}
            labelMinWidth={REGULAR_LABEL_WIDTH}
            labelGap={REGULAR_LABEL_GAP}
            required
          >
            <Input
              placeholder={t('warehouses.forms.addressPlaceholder')}
              value={`${formik.values.loading_warehouse_latitude} - ${formik.values.loading_warehouse_longitude}`}
              disabled
            />
          </FormGroup>

          <FormGroup
            error={formik.errors.loading_warehouse_address}
            label={t('warehouses.forms.textAddress')}
            labelMinWidth={REGULAR_LABEL_WIDTH}
            labelGap={REGULAR_LABEL_GAP}
          >
            <Input
              placeholder={t('warehouses.forms.addressPlaceholder')}
              value={formik.values.loading_warehouse_address}
              disabled
            />
          </FormGroup>

          <CommonLoadWHForm formik={formik} />
        </SharedModalInputs>
      </SharedModalContent>

      <ADefaultModalFooter
        progress={formProgress}
        confirmButtonText={t('common:save')}
        cancelButtonText={t('common:cancel')}
        onConfirm={() => formik.handleSubmit()}
        onClose={() => setIsOpen(false)}
      />
    </ASharedModal>
  )
}
