import { Typography, defaultTheme, TypographyType } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC, HTMLAttributes, ReactNode } from 'react'
import { useMediaQuery } from 'react-responsive'

export const Key = styled.div<{
  horizontal?: boolean
  typography: TypographyType
}>`
  ${({ typography }) => Typography[typography]};
  color: ${defaultTheme.color.textSecondary60a};
  margin-bottom: ${({ horizontal }) => (horizontal ? 0 : '4px')};
`

export const Value = styled.div<{
  isNumber?: boolean
  horizontal?: boolean
  typography: TypographyType
}>`
  ${({ typography }) => Typography[typography]};
  color: ${defaultTheme.color.textPrimary900};
  font-variant-numeric: ${({ isNumber }) => (isNumber ? 'tabular-nums' : 'normal')};
  display: flex;
  flex-direction: column;
`

export interface KeyValueProps extends HTMLAttributes<HTMLDivElement> {
  label: ReactNode
  isNumber?: boolean
  horizontal?: boolean
  labelTypo?: TypographyType
  valueTypo?: TypographyType
}

export const KeyValue: FC<KeyValueProps> = ({
  label,
  isNumber,
  children,
  horizontal,
  labelTypo,
  valueTypo = 'bodyLarge',
  ...otherProps
}) => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  const typography = labelTypo || (isMobile ? 'bodySmall' : 'bodyMedium')

  return (
    <div {...otherProps}>
      <Key horizontal={horizontal} typography={typography}>
        {label}
      </Key>
      <Value isNumber={isNumber} typography={valueTypo}>
        {children}
      </Value>
    </div>
  )
}
