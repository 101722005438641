import { Progress } from '@agro-club/agroclub-shared'
import { isBrazil, isUS } from 'env'
import { DealStatusTypes } from 'modules/domain/deal/types'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const useDealBoardColumns = () => {
  const { t } = useTranslation('deal')
  const defaultColumns = [
    { statusDeal: DealStatusTypes.NEW, label: t('potentialDeals') },
    {
      statusDeal: DealStatusTypes.IN_PROGRESS,
      label: t('statuses.in_progress'),
    },
    { statusDeal: DealStatusTypes.CUSTOMER_NEGOTIATIONS, label: t('statuses.customer_negotiations') },
    { statusDeal: DealStatusTypes.AGREED, label: t('statuses.agreed') },
    { statusDeal: DealStatusTypes.CONTRACT_SIGNED, label: t('statuses.contract_signed') },
    { statusDeal: DealStatusTypes.PAYMENT_SHIPMENT, label: t('statuses.payment_shipment') },
    { statusDeal: DealStatusTypes.SHIPMENT_COMPLETE, label: t('statuses.shipment_complete') },
    { statusDeal: DealStatusTypes.DOCUMENTS_ACCEPTED, label: t('statuses.documents_accepted') },
    { statusDeal: DealStatusTypes.PAYMENT_COMPLETE, label: t('statuses.payment_complete') },
  ]
  if (isBrazil) {
    return [
      { statusDeal: DealStatusTypes.NEW, label: t('statuses.new') },
      {
        statusDeal: DealStatusTypes.IN_PROGRESS,
        label: t('statuses.in_progress'),
      },
      { statusDeal: DealStatusTypes.AGREED, label: t('statuses.agreed') },
      { statusDeal: DealStatusTypes.CONTRACT_SIGNED, label: t('statuses.contract_signed') },
      { statusDeal: DealStatusTypes.PAYMENT_SHIPMENT, label: t('statuses.payment_shipment') },
      { statusDeal: DealStatusTypes.PICK_UP_COMPLETE, label: t('statuses.pick_up_complete') },
      { statusDeal: DealStatusTypes.SHIPMENT_COMPLETE, label: t('statuses.shipment_complete') },
    ]
  } else if (isUS) {
    return defaultColumns.filter((c) => c.statusDeal != DealStatusTypes.CUSTOMER_NEGOTIATIONS)
  }

  return defaultColumns
}

export const useColumnContentMargin = (elementId: string, progress) => {
  const [boundingTop, setBoundingTop] = useState(278)

  const updateBoundingTop = () => {
    const element = document.getElementById(elementId)
    const boundingRectTop = element?.getBoundingClientRect().top || 0
    setBoundingTop(boundingRectTop)
  }
  useEffect(() => {
    if (progress !== Progress.SUCCESS) return
    updateBoundingTop()
    window.addEventListener('resize', updateBoundingTop)
    return () => {
      window.removeEventListener('resize', updateBoundingTop)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress])
  return boundingTop
}
