import { AntdTable, Progress } from '@agro-club/agroclub-shared'
import { Bid, BidPricePeriod } from 'modules/domain/bid/types'
import { FC, useMemo } from 'react'
import { isBidWithPricePeriods } from 'modules/domain/bid/utils'
import { isFuturesEnabled } from 'env'
import { ATableFullPageWithoutData } from 'views/components/Table/ATableFullPageWithoutData'

import useBidPriceColumns from './useBidPriceColumns'
import { type User } from 'modules/domain/user/types'
import { TableDataObject } from 'modules/domain/common/hooks'
import { InternalTableFullPageWithoutDataProps } from 'views/components/Table/InternalTableFullPageWithoutData'

type BidPricesTableProps = {
  topBid?: string
  nested?: boolean
  pricePeriods?: BidPricePeriod[]
  tableData: TableDataObject<Bid>
  paramsData: ParamsData
  user?: User
}

type ParamsData = Pick<InternalTableFullPageWithoutDataProps, 'listParamsUpdated' | 'listRequestParams'>

const BidPricesTable: FC<BidPricesTableProps> = ({
  topBid,
  nested = false,
  pricePeriods = [],
  tableData,
  paramsData,
  user,
}) => {
  const columns = useBidPriceColumns({
    refetch: () => {
      return tableData.refetch(true)
    },
    nested,
  })

  const rowClassName = (record, index) => {
    if (nested) {
      return ''
    }
    if (index === 0 && topBid && record.id === Number(topBid)) {
      return 'bold-row'
    }
    return ''
  }

  const expandable = useMemo(() => {
    if (!isFuturesEnabled || nested) {
      return
    }
    return {
      showExpandColumn: true,
      rowExpandable: (row: Bid) => isBidWithPricePeriods(row),
      expandedRowRender: (row: Bid) => (
        <BidPricesTable
          pricePeriods={row.price_periods}
          nested={true}
          tableData={tableData}
          paramsData={paramsData}
          user={user}
        />
      ),
    }
  }, [nested, tableData, paramsData, user])

  // move single periods futures to root level
  const data = useMemo(
    () =>
      (tableData.data ?? []).map((bid) =>
        bid.price_periods && bid.price_periods.length === 1
          ? { ...bid, ...bid.price_periods[0], parent_id: null, price_periods: [] }
          : bid,
      ),
    [tableData],
  )

  return nested ? (
    <AntdTable
      columns={columns}
      showHeader={false}
      progress={Progress.SUCCESS}
      rowKey={'id'}
      data={pricePeriods}
      disablePagination
    />
  ) : (
    <ATableFullPageWithoutData
      rowClassName={rowClassName}
      columns={columns}
      expandable={expandable}
      rowHoverGray
      {...tableData}
      {...paramsData}
      data={data}
    />
  )
}

export default BidPricesTable
