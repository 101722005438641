import { DocumentWithTypeFile, ListRequestParams, RespFile } from 'modules/domain/types'
import { apiClient } from 'modules/utils/httpClient'
import { ListResponse } from 'types/api'

// We need to format array params into strings
// to be able to send them to the server in a correct format
const commaSeparatedFields = ['district', 'region', 'country']

export const getTableData = async <T = any>(
  endpoint: string,
  requestParams: ListRequestParams,
  abortController: AbortController | null,
) => {
  const signal = abortController ? abortController.signal : undefined
  const filter = { ...requestParams.filter }

  for (const [key, value] of Object.entries(filter)) {
    if (commaSeparatedFields.includes(key)) {
      filter[key] = Array.isArray(value) ? value.join(',') : value
    }
  }

  if (requestParams.transformBeforeSend) {
    for (const [key, transformFunc] of Object.entries(requestParams.transformBeforeSend)) {
      filter[key] = transformFunc(filter[key])
    }
  }

  const data = await apiClient.get<ListResponse<T>>(
    endpoint,
    {
      page: requestParams.page,
      sort: requestParams.sort,
      ...filter,
      ...(requestParams.pageSize ? { page_size: requestParams.pageSize } : {}),
    },
    { signal },
  )

  return {
    list: data.results,
    total: data.count,
    pageSize: data.page_size,
  }
}

export const uploadFile = (url: string, file: File) => {
  const form = new FormData()
  form.append('uploaded_file', file)
  return apiClient.post<RespFile>(url, form)
}

export const uploadDocuments = (url: string, document_type: string, file: File) => {
  const form = new FormData()
  form.append('uploaded_file', file)
  form.append('document_type', document_type)
  return apiClient.post<DocumentWithTypeFile>(url, form)
}
