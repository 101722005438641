import { AddAddressModal } from 'views/pages/User/UserDetail/UserRightPanel/Addresses/AddAddressModal'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { FormikFieldsType } from 'modules/domain/types'
import { AButton } from 'views/components/Analytics'
import { useMediaQuery } from 'react-responsive'
import { AddressBrazil } from './AddressBrazil'
import { AddressCommon } from './AddressCommon'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC, useState } from 'react'
import { isBrazil } from 'env'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`

export type PropsFormAddress = {
  formik: FormikFieldsType
  required?: boolean
  disabled?: boolean
  fieldName?: string
  label?: string
  userId: string
}

export const FormAddress: FC<PropsFormAddress> = ({ fieldName = 'address', required, userId, formik, label }) => {
  const [showModal, setShowModal] = useState(false)
  const { t } = useTranslation('')
  const isMobile = useMediaQuery({ query: `(max-width: ${defaultTheme.queries.mobile})` })

  const toggleModal = () => setShowModal(!showModal)
  const handleAddAddress = (id) => formik.setFieldValue(fieldName, String(id))

  const AddressComponent = isBrazil ? AddressBrazil : AddressCommon
  const addButtonHandler = isMobile ? undefined : toggleModal

  return (
    <Wrapper>
      <AddressComponent
        addButtonHandler={addButtonHandler}
        endpoint={endpoints.userAddress(userId)}
        fieldName={fieldName}
        required={required}
        formik={formik}
        label={label}
      />
      {isMobile && (
        <AButton onClick={toggleModal} id="openAddAddressModal" Icon={Icons.IconPlus} intent="approve" size="biggest">
          {t('common:add')}
        </AButton>
      )}
      {showModal && <AddAddressModal onClose={toggleModal} onSuccess={handleAddAddress} userId={userId} />}
    </Wrapper>
  )
}

export default FormAddress
