import { ItemCard as CardWrapper, CardButtonWrapper, BidCardTags } from 'views/styled/ItemCardStyles'
import { Bid, BidStatus, USBid } from 'modules/domain/bid/types'
import { AntPopover, Icons } from '@agro-club/agroclub-shared'
import BidRoutes from 'views/pages/Bid/routes'
import { generatePath } from 'react-router'
import { FC, useState } from 'react'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { isUS } from 'env'
import { USBidCardInfo } from './USBidCardInfo'
import { BarchartIcon } from 'views/pages/Bid/BidDetail/Bid/BarchartIcon'
import { BidCardInfo } from './BidCardInfo'
import { BidFuturesTag, BidTeamTag, USContractTypeTag } from 'views/components/Tags'
import { AButton } from 'views/components/Analytics'
import { BidPopoverContent } from './BidPopoverContent'

type Props = {
  setActiveBid: (arg: Bid) => void
  activeCard: boolean
  bid: Bid | USBid
}

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  max-width: 248px;
`

export const BidCard: FC<Props> = ({ bid, setActiveBid, activeCard }) => {
  const handleClick = useAnalyticsClickHandler('bid')
  const [popoverVisible, setPopoverVisible] = useState(false)

  const { bidId } = useParams()

  const cardInfo = isUS ? <USBidCardInfo bid={bid as USBid} /> : <BidCardInfo bid={bid} />

  const tags = (
    <>
      <BarchartIcon source={bid.source} />
      <BidTeamTag bid={bid} />
      <USContractTypeTag bid={bid} />
      <BidFuturesTag bid={bid} />
    </>
  )

  const activeBid = bidId === String(bid.id)

  return (
    <CardWrapper
      onClick={handleClick(() => setActiveBid(bid))}
      id={activeBid ? bidId : ''}
      activeCard={activeCard}
      status={bid.status}
    >
      <BidCardTags>{tags}</BidCardTags>
      <CardButtonWrapper>
        {isUS && bid.status === BidStatus.published ? (
          <AntPopover
            content={<BidPopoverContent setPopoverVisible={setPopoverVisible} bid={bid as USBid} />}
            placement="bottomRight"
            trigger="click"
            open={popoverVisible}
            onOpenChange={() => setPopoverVisible(!popoverVisible)}
          >
            <AButton id="bitActions" intent="white" Icon={Icons.IconMoreHoriz} onClick={(e) => e.stopPropagation()} />
          </AntPopover>
        ) : (
          <AButton
            Icon={Icons.IconOpen}
            to={generatePath(bid.status === BidStatus.potential ? BidRoutes.PotentialBidDetail : BidRoutes.Details, {
              id: bid.id,
            })}
            id="goToBid"
            intent="white"
            analyticsPayload={{ id: bid.id }}
          />
        )}
      </CardButtonWrapper>
      <InfoWrapper>{cardInfo}</InfoWrapper>
    </CardWrapper>
  )
}
