import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { AButton } from 'views/components/Analytics'
import { ExternalLink, Icons, Text } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { FC } from 'react'
import { TtnDocument } from 'views/pages/LogisticsKR/types'
import { useTranslation } from 'react-i18next'
import useFormatDate from 'hooks/useFormatDate'
import { useAProgress } from 'hooks/useAProgress'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { refetchFunc } from 'modules/domain/common/hooks'

const ItemContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

type Props = {
  file: TtnDocument
  refetch: refetchFunc
  refetchTripsTable: () => void
  setDeletedTtnIdForSync?: (docId: string) => void
}

export const FileTtnItem: FC<Props> = ({ file, refetch, refetchTripsTable, setDeletedTtnIdForSync }) => {
  const { t } = useTranslation('logisticsKR')
  const formatDate = useFormatDate()

  const [removeProgress, removeHandler] = useAProgress(
    async (tripId: number, docId: number) =>
      await apiClient.delete(endpoints.LogisticsKR.tripsDocument(String(tripId), String(docId))),
    {
      eventName: 'removeTripTtnFile',
      onSuccess: () => {
        setDeletedTtnIdForSync?.(String(file.uploadedfile))
        refetch(true)
        refetchTripsTable()
      },
    },
  )

  return (
    <BorderedItem>
      <ItemContentWrapper>
        <div>
          <ExternalLink href={file.uploaded_file} target="_blank">
            {file.original_filename}
          </ExternalLink>
          <Text color="primary">
            {t('details.accordion.ttn')}: {file.ttn_number}, {t('details.accordion.fileLoadDate')}:{' '}
            {formatDate(file.uploaded_at)}
          </Text>
        </div>
        <AButton
          id="removeTtnFile"
          onClick={() => removeHandler(file.trip, file.uploadedfile)}
          progress={removeProgress}
          intent="white"
          Icon={Icons.IconRemove}
        />
      </ItemContentWrapper>
    </BorderedItem>
  )
}
