import { FC } from 'react'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { REGULAR_LABEL_GAP, REGULAR_LABEL_WIDTH } from '../constants'
import { controlContainerStyles, TimeInputContainer, TimeInputWrapper } from './styled'
import { useTranslation } from 'react-i18next'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
  label: string
  fromFormikValue: string
  toFormikValue: string
}

export const TimeInput: FC<Props> = ({ formik, label, fromFormikValue, toFormikValue }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <FormGroup
      error={formik.errors[fromFormikValue] || formik.errors[toFormikValue]}
      label={label}
      labelMinWidth={REGULAR_LABEL_WIDTH}
      labelGap={REGULAR_LABEL_GAP}
      controlContainerStyle={controlContainerStyles()}
    >
      <TimeInputContainer>
        <TimeInputWrapper>
          <Input
            placeholder={t('list.createForm.placeholders.loading_schedule_from')}
            {...formik.getFieldProps(fromFormikValue)}
            invalid={!!formik.errors[fromFormikValue]}
          />
        </TimeInputWrapper>
        —
        <TimeInputWrapper>
          <Input
            placeholder={t('list.createForm.placeholders.loading_schedule_to')}
            {...formik.getFieldProps(toFormikValue)}
            invalid={!!formik.errors[toFormikValue]}
          />
        </TimeInputWrapper>
      </TimeInputContainer>
    </FormGroup>
  )
}
