import { FormGroup } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { NumberInput } from 'views/ui/NumberInput/NumberInput'
import { REGULAR_LABEL_WIDTH, REGULAR_LABEL_GAP } from '../constants'
import { UnloadingType } from '../types'
import { SimpleOptionsSelect } from './CarsForm/SimpleOptionsSelect'
import { BoolRadio } from 'views/components/BoolRadio/BoolRadio'
import { controlContainerStyles, ShortField } from './styled'
import { TimeInput } from './TimeInput'
import { VehicleTypeCheckboxGroup } from 'views/components/CheckboxGroup/VehicleTypeCheckboxGroup'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const CommonUnloadWHForm: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('logisticsKR')

  return (
    <>
      <VehicleTypeCheckboxGroup fieldName="unloading_warehouse_vehicle_type" formik={formik} />

      <BoolRadio
        formik={formik}
        label={t('list.createForm.labels.is_dump_truck')}
        fieldName="unloading_warehouse_is_dump_truck"
      />

      <SimpleOptionsSelect
        formik={formik}
        fieldName="unloading_warehouse_unloading_type"
        EnumType={UnloadingType}
        label={t('list.createForm.labels.unload_type')}
        optionsTranslations="logisticsKR:directions"
        controlContainerStyle={{ maxWidth: 238, flex: '0 0 100%' }}
        allowClear={false}
        required
      />

      <FormGroup
        error={formik.errors.unloading_warehouse_volume_per_day}
        label={t('list.createForm.labels.volume_per_day', { type: t('list.createForm.unload_type') })}
        labelMinWidth={REGULAR_LABEL_WIDTH}
        labelGap={REGULAR_LABEL_GAP}
        controlContainerStyle={controlContainerStyles()}
      >
        <ShortField>
          <NumberInput
            placeholder={t('list.createForm.placeholders.volume_per_day', { type: t('list.createForm.unload_type') })}
            {...formik.getFieldProps('unloading_warehouse_volume_per_day')}
            invalid={!!formik.errors.unloading_warehouse_volume_per_day}
            onChange={(value) => formik.setFieldValue('unloading_warehouse_volume_per_day', value)}
          />
        </ShortField>
      </FormGroup>

      <TimeInput
        formik={formik}
        label={t('list.createForm.labels.loading_schedule', { type: t('list.createForm.unload_type') })}
        fromFormikValue="unloading_warehouse_schedule_from"
        toFormikValue="unloading_warehouse_schedule_to"
      />

      <BoolRadio
        formik={formik}
        label={t('list.createForm.labels.works_on_weekend')}
        fieldName="unloading_warehouse_works_on_weekend"
      />

      <BoolRadio
        formik={formik}
        label={t('list.createForm.labels.car_delivery')}
        fieldName="unloading_warehouse_car_delivery"
      />

      <BoolRadio
        formik={formik}
        label={t('list.createForm.labels.railway_delivery')}
        fieldName="unloading_warehouse_railway_delivery"
      />
    </>
  )
}
