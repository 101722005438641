import { User } from './types'

export const getUserCompanyName = (user?: User) => user?.profile?.company?.name || ''

export const getUserPhone = (user?: User) => user?.phone || ''

export const getUserTeamId = (user?: User, default_?: number | null) => user?.profile?.team?.id ?? default_

export const getUserName = (user?: User) =>
  [user?.profile?.first_name, user?.profile?.last_name, user?.profile?.patronymic_name]
    .map((s) => s?.trim())
    .filter(Boolean)
    .join(' ') ||
  user?.phone ||
  ''
