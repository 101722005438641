import { FC } from 'react'
import { FormGroup, Radio, RadioItem } from '@agro-club/agroclub-shared'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import FormSelectShared from 'views/components/FormSelectShared'
import { REGULAR_LABEL_GAP, REGULAR_LABEL_WIDTH } from '../../../../../../constants'
import { SharedModalInputs } from 'views/components/SharedModal'
import { removeSeconds } from 'views/pages/LogisticsKR/helpers'
// import { REACT_APP_YA_MAPS_API_KEY } from 'env'
import { CommonLoadWHForm } from 'views/pages/LogisticsKR/components/CommonLoadWHForm'
import { FormItemWrapper } from 'views/styled/common'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
  isNewAddress: boolean
  setIsNewAddress: (value: boolean) => void
}

export const LoadingParameters: FC<Props> = ({ formik, isNewAddress, setIsNewAddress }) => {
  const { t } = useTranslation('logisticsKR')

  const handleAddressOptionLoad = (warehouse) => {
    if (!warehouse) return

    const {
      address,
      latitude,
      longitude,
      gates_height,
      loader_type,
      scales_capacity,
      scales_remoteness,
      scales_length,
      volume_per_day,
      vehicle_type,
      schedule_from,
      schedule_to,
      works_on_weekend,
      car_delivery,
      railway_delivery,
    } = warehouse.dto

    formik.setFieldValue('loading_warehouse_address', address)
    formik.setFieldValue('loading_warehouse_latitude', latitude)
    formik.setFieldValue('loading_warehouse_longitude', longitude)
    formik.setFieldValue('loading_warehouse_gates_height', gates_height)
    formik.setFieldValue('loading_warehouse_loader_type', loader_type)
    formik.setFieldValue('loading_warehouse_scales_capacity', scales_capacity)
    formik.setFieldValue('loading_warehouse_scales_remoteness', scales_remoteness)
    formik.setFieldValue('loading_warehouse_scales_length', scales_length)
    formik.setFieldValue('loading_warehouse_volume_per_day', volume_per_day)
    formik.setFieldValue('loading_warehouse_vehicle_type', vehicle_type)
    formik.setFieldValue('loading_warehouse_schedule_from', removeSeconds(schedule_from))
    formik.setFieldValue('loading_warehouse_schedule_to', removeSeconds(schedule_to))
    formik.setFieldValue('loading_warehouse_works_on_weekend', works_on_weekend)
    formik.setFieldValue('loading_warehouse_car_delivery', car_delivery)
    formik.setFieldValue('loading_warehouse_railway_delivery', railway_delivery)
  }

  /*   const handleNewAddress = (value) => {
    if (!value) return

    const { address, coords, geoObject } = value
    const [latitude, longitude] = coords

    formik.setFieldValue('loading_warehouse_id', null)
    formik.setFieldValue('loading_warehouse_address', address)
    formik.setFieldValue('loading_warehouse_latitude', latitude)
    formik.setFieldValue('loading_warehouse_longitude', longitude)
    formik.setFieldValue('loading_warehouse_geo_object', geoObject)
  } */

  return (
    <SharedModalInputs>
      <FormGroup
        label={t('list.createForm.labels.systemAddress')}
        labelMinWidth={REGULAR_LABEL_WIDTH}
        labelGap={REGULAR_LABEL_GAP}
      >
        <FormItemWrapper>
          <Radio onChange={(value) => setIsNewAddress(!!value)} value={isNewAddress ? 1 : 0}>
            <RadioItem value={0} label={t('list.createForm.labels.existing')} />
            <RadioItem value={1} label={t('list.createForm.labels.new')} />
          </Radio>
        </FormItemWrapper>
      </FormGroup>

      {isNewAddress ? (
        <FormGroup
          error={formik.errors.loading_warehouse_address}
          label={t('list.createForm.labels.address')}
          labelMinWidth={REGULAR_LABEL_WIDTH}
          labelGap={REGULAR_LABEL_GAP}
          required
        >
          {/*  <YandexAddressForm
            apikey={REACT_APP_YA_MAPS_API_KEY}
            onChange={handleNewAddress}
            placeholder={t('list.createForm.placeholders.systemAddress')}
            initialSearch={formik.values.loading_warehouse_address}
            initialCoords={[formik.values.loading_warehouse_latitude, formik.values.loading_warehouse_longitude]}
          /> */}
        </FormGroup>
      ) : (
        <FormSelectShared
          required
          endpoint={endpoints.LogisticsKR.loadingWarehouse()}
          fieldName="loading_warehouse_id"
          placeholder={t('list.createForm.placeholders.address')}
          label={t('list.createForm.labels.address')}
          labelMinWidth={REGULAR_LABEL_WIDTH}
          formik={formik}
          labelGap={REGULAR_LABEL_GAP}
          onSelectedOptionLoaded={handleAddressOptionLoad}
          getLabel={(dto) => dto.address}
        />
      )}

      <CommonLoadWHForm formik={formik} />
    </SharedModalInputs>
  )
}
