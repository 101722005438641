import { FC, useEffect } from 'react'

import { DocumentFile, DocumentType, DocumentWithTypeFile } from 'modules/domain/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import * as userManagers from 'modules/domain/user/managers'
import { endpoints } from 'modules/endpoints'
import { AFilesByTypes } from 'views/components/FilesByTypes/AFilesByTypes'
import { useAProgress } from 'hooks/useAProgress'
import { Progress } from '@agro-club/agroclub-shared'

interface Props {
  setSummary: (arg: number) => void
  userId: string
}

export const Documents: FC<Props> = ({ userId, setSummary }) => {
  const [, userDocumentTypes = []] = useSingleEntity<DocumentType[]>(endpoints.userDocumentTypes(userId))
  const [progress, userDocuments = [], userDocumentsRefetch] = useSingleEntity<DocumentFile[]>(
    endpoints.userDocuments(userId),
  )
  const [uploadProgress, upload] = useAProgress<DocumentWithTypeFile>(
    (documentType: DocumentType['slug'], file: File) => userManagers.uploadDocuments(userId, documentType, file),
    {
      eventName: 'uploadDocument',
      onSuccess: () => userDocumentsRefetch(),
    },
  )

  const [deleteProgress, deleteDoc] = useAProgress<unknown>(
    (documentId: string) => userManagers.deleteDocumentFile(userId, documentId),
    {
      eventName: 'deleteDocument',
      onSuccess: () => userDocumentsRefetch(),
    },
  )

  useEffect(() => {
    if (progress === Progress.SUCCESS) setSummary(userDocuments.length)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDocuments.length])

  return (
    <AFilesByTypes
      types={userDocumentTypes}
      files={userDocuments}
      onUpload={upload}
      onDelete={deleteDoc}
      uploadProgress={uploadProgress}
      deleteProgress={deleteProgress}
    />
  )
}
