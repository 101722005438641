import { Company, FactoringStatus } from 'modules/domain/company/types'
import { AntTag, Icons } from '@agro-club/agroclub-shared'
import { refetchFunc } from 'modules/domain/common/hooks'
import { apiClient } from 'modules/utils/httpClient'
import { AButton } from 'views/components/Analytics'
import { useAProgress } from 'hooks/useAProgress'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { ButtonsWrapper } from '../styled'

interface Props {
  company: Company
  refetchCompany: refetchFunc
}

export const ChangeFactoringStatus = ({ company, refetchCompany }: Props) => {
  const { t } = useTranslation('company')
  const [changeStatusProgress, changeStatus] = useAProgress(
    (new_status) =>
      apiClient.post<{ new_status: string }>(
        endpoints.changeFactoringStatus(company.id, company.factoring_request?.id),
        { new_status },
      ),
    {
      eventName: 'changeFactoringStatus',
      analyticsPayload: {
        id: company.id,
      },
      onSuccess: () => refetchCompany(true),
    },
  )

  if (company.factoring_request?.status !== FactoringStatus.in_progress) {
    return <AntTag color="white">{t('viewed')}</AntTag>
  }

  return (
    <ButtonsWrapper>
      <AButton
        intent="secondary"
        size="small"
        id="submit|acceptFactoring"
        Icon={Icons.IconCheck}
        onClick={() => changeStatus(FactoringStatus.accepted)}
        progress={changeStatusProgress}
      >
        {t('accept')}
      </AButton>

      <AButton
        intent="destruct"
        size="small"
        id="submit|cancelFactoring"
        Icon={Icons.IconClear}
        onClick={() => changeStatus(FactoringStatus.cancelled)}
        progress={changeStatusProgress}
      >
        {t('cancel')}
      </AButton>
    </ButtonsWrapper>
  )
}
