import { FC, useContext, useMemo, useState } from 'react'
import { BuyerSpecifications } from './BuyerSpecifications/BuyerSpecifications'

import { AntCollapse, Panel, Progress } from '@agro-club/agroclub-shared'
import { isBidContractsEnabled, isBrazil, isRussia } from 'env'
import { AuthContext } from 'modules/context/AuthContext'
import { CommentType } from 'modules/domain/comment/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { DealStatusTypes } from 'modules/domain/deal/types'
import { Declaration } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { useTranslation } from 'react-i18next'
import AntCollapseHeader from 'views/components/AntCollapseHeader/AntCollapseHeader'
import { SummaryComments } from 'views/components/AntCollapseHeader/SummaryComments'
import { SummaryCount } from 'views/components/AntCollapseHeader/SummaryCount'
import { SummaryThereIsNo } from 'views/components/AntCollapseHeader/SummaryThereIsNo'
import AComments from 'views/components/Comments/AComments'
import { RightPanelComments } from 'views/components/Comments/styled'
import { RatingsList } from 'views/pages/Deal/DealDetail/DealRating'
import { isRatingAvailable, isStatusAfter } from 'views/pages/Deal/DealDetail/helpers'
import { useDealContext } from '../DealContext'
import { ContractDocs } from './ContractDocs/ContractDocs'
import { DealContractSign } from './DealContract/DealContractSign'
import { DealFiles } from './DealFiles/DealFiles'
import { History } from './DealHistory/History'
import { DealPurchaseDocuments } from './DealPurchaseDocuments'
import { DealPurchaseFiles } from './DealPurchaseFiles'
import { DealSellerDocuments } from './DealSellerDocuments'
import { DealSellerFiles } from './DealSellerFiles'
import { DealAccordionShipping } from './DealShipping/DealAccordionShipping'
import { ExportBlock } from './Export/ExportBlock'
import { FarmerLoading } from './Loading/FarmerLoading'
import { ShipLoading } from './Loading/ShipLoading'

export const DealAccordion: FC<{
  declaration: Declaration | undefined
  declarationRefetch: refetchFunc
  declarationProgress: Progress
}> = ({ declaration, declarationRefetch, declarationProgress }) => {
  const { deal, dealRefetch } = useDealContext()
  const { t } = useTranslation('deal')
  const { profile: currentUser } = useContext(AuthContext)

  const purchaseId = deal.purchase_bid.owner.id
  const sellerId = deal.sale_bid.owner.id

  const isShowRating = isRatingAvailable(
    deal.status as DealStatusTypes,
    deal?.closed_at,
    currentUser?.permissions ?? [],
  )
  const isShowShipLoading = isRussia && isStatusAfter(deal.status, DealStatusTypes.PAYMENT_SHIPMENT)

  const isShowFarmerLoading = isRussia && isStatusAfter(deal.status, DealStatusTypes.IN_PROGRESS)

  const saleBidId = deal.sale_bid?.parent_id || deal.sale_bid.id
  const purchaseBidId = deal.purchase_bid?.parent_id || deal.purchase_bid.id

  const isContractSignReady = deal.status !== DealStatusTypes.NEW
  const [commentsSummary, setCommentsSummary] = useState<string>('')
  const [hasDealFiles, setHasDealFiles] = useState(false)
  const [totalSaleFiles, setTotalSaleFiles] = useState<number>()
  const [totalPurchaseFiles, setTotalPurchaseFiles] = useState<number>()
  const [totalSellerDocuments, setTotalSellerDocuments] = useState<number>()
  const [totalPurchaseDocuments, setTotalPurchaseDocuments] = useState<number>()
  const [hasBuyerSpecification, setHasBuyerSpecification] = useState(false)
  const [hasShipping, setHasShipping] = useState(false)
  const [shipLoadSummary, setShipLoadSummary] = useState<number>()
  const [farmerLoadSummary, setFarmerLoadSummary] = useState<number>()
  const [hasSaleContractDocs, setHasSaleContractDocs] = useState<boolean>(false)
  const [hasPurchaseContractDocs, setHasPurchaseContractDocs] = useState<boolean>(false)
  const [hasExports, setHasExports] = useState<boolean>(false)

  const options = useMemo(
    () =>
      [
        { value: 'comment', title: t('accordion.comments'), isShow: true },
        { value: 'dealFiles', title: t('accordion.dealFiles.deal_files'), isShow: !isBrazil },
        { value: 'saleFiles', title: t('accordion.saleFiles'), isShow: isRussia },
        { value: 'purchaseFiles', title: t('accordion.purchaseFiles'), isShow: isRussia },
        { value: 'saleDocuments', title: t('accordion.saleDocuments'), isShow: true },
        { value: 'purchaseDocuments', title: t('accordion.purchaseDocuments'), isShow: true },

        { value: 'buyerSpecifications', title: t('accordion.buyer_specifications.title'), isShow: !isBrazil },

        { value: 'saleContractDocs', title: t('accordion.sellerContractFiles'), isShow: isBidContractsEnabled },
        { value: 'purchaseContractDocs', title: t('accordion.purchaseContractFiles'), isShow: isBidContractsEnabled },

        { value: 'shipping', title: t('accordion.shipping'), isShow: !isBrazil },
        { value: 'export', title: t('accordion.export'), isShow: isRussia },
        { value: 'shipLoading', title: t('accordion.loading.shipTitle'), isShow: isShowShipLoading },
        { value: 'farmerLoading', title: t('accordion.loading.farmerTitle'), isShow: isShowFarmerLoading },
        {
          value: 'contractSign',
          title: t('accordion.contract_sign'),
          isShow: isContractSignReady,
        },
        {
          value: 'rating',
          title: t('accordion.seller_rating'),
          isShow: isShowRating,
        },
        {
          value: 'history',
          title: t('common:history'),
          isShow: true,
        },
      ].filter((option) => option.isShow),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isContractSignReady, isShowRating],
  )

  const [activeKey, setActiveKey] = useState<string | string[]>(options[0].value)
  const components = {
    comment: currentUser?.id && (
      <RightPanelComments>
        <AComments
          url={endpoints.dealComment(deal.id)}
          popSummary={setCommentsSummary}
          type={CommentType.deal}
          id={currentUser.id}
          eventPostFix="deal"
          maxHeight={400}
        />
      </RightPanelComments>
    ),
    dealFiles: (
      <DealFiles
        declarationProgress={declarationProgress}
        declarationRefetch={declarationRefetch}
        setSummary={setHasDealFiles}
        declaration={declaration}
        deal={deal}
        dealRefetch={dealRefetch}
      />
    ),
    saleFiles: <DealSellerFiles deal={deal} setSummary={setTotalSaleFiles} />,
    purchaseFiles: <DealPurchaseFiles setSummary={setTotalPurchaseFiles} deal={deal} />,
    saleDocuments: <DealSellerDocuments setSummary={setTotalSellerDocuments} ownerId={sellerId} />,
    purchaseDocuments: <DealPurchaseDocuments setSummary={setTotalPurchaseDocuments} ownerId={purchaseId} />,
    buyerSpecifications: (
      <BuyerSpecifications setSummary={setHasBuyerSpecification} deal={deal} dealRefetch={dealRefetch} />
    ),
    saleContractDocs: (
      <ContractDocs
        setSummary={setHasSaleContractDocs}
        contractId={deal.sale_contract?.id}
        bidId={saleBidId}
        deal={deal}
      />
    ),
    purchaseContractDocs: (
      <ContractDocs
        setSummary={setHasPurchaseContractDocs}
        contractId={deal.purchase_contract?.id}
        bidId={purchaseBidId}
        deal={deal}
      />
    ),
    shipping: <DealAccordionShipping deal={deal} setSummary={setHasShipping} />,
    export: <ExportBlock deal={deal} refetchDeal={dealRefetch} setSummary={setHasExports} />,
    shipLoading: <ShipLoading setSummary={setShipLoadSummary} />,
    farmerLoading: <FarmerLoading setLoadSummary={setFarmerLoadSummary} />,
    contractSign: <DealContractSign deal={deal} dealRefetch={dealRefetch} />,
    rating: <RatingsList dealId={deal.id} dealStatus={deal.status} />,
    history: <History deal={deal} />,
  }

  const summary = {
    comment: <SummaryComments summary={commentsSummary} />,
    dealFiles: <SummaryThereIsNo hasData={hasDealFiles} />,
    saleFiles: <SummaryCount total={totalSaleFiles} />,
    purchaseFiles: <SummaryCount total={totalPurchaseFiles} />,
    saleDocuments: <SummaryCount total={totalSellerDocuments} />,
    purchaseDocuments: <SummaryCount total={totalPurchaseDocuments} />,
    buyerSpecifications: <SummaryThereIsNo hasData={hasBuyerSpecification} />,
    saleContractDocs: <SummaryThereIsNo hasData={hasSaleContractDocs} />,
    purchaseContractDocs: <SummaryThereIsNo hasData={hasPurchaseContractDocs} />,
    shipping: <SummaryThereIsNo hasData={hasShipping} />,
    shipLoading: <SummaryThereIsNo hasData={shipLoadSummary} />,
    farmerLoading: <SummaryThereIsNo hasData={farmerLoadSummary} />,
    export: <SummaryThereIsNo hasData={hasExports} />,
  }

  return (
    <div>
      <AntCollapse accordion bordered={false} defaultActiveKey={options[0].value} onChange={(key) => setActiveKey(key)}>
        {options.map((option) => {
          return (
            <Panel
              forceRender
              key={option.value}
              header={
                <AntCollapseHeader title={option.title} summary={activeKey !== option.value && summary[option.value]} />
              }
            >
              {components[option.value]}
            </Panel>
          )
        })}
      </AntCollapse>
    </div>
  )
}
