import { FormGroup, FormGroupProps, Progress } from '@agro-club/agroclub-shared'
import { FC, useEffect, useState } from 'react'
import { apiClient } from 'modules/utils/httpClient'
import { FormikHelpers, FormikState } from 'formik'
import { RespFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { t } from 'i18next'
import AUploadFiles from '../UploadFiles/AUploadFiles'

interface Props extends FormGroupProps {
  formik: FormikState<any> & FormikHelpers<any>
  progress?: Progress
  fieldName: string
  isSingleFile?: boolean
  text?: string
  maxWidthFileUpload?: number
  files?: RespFile[]
}

type FileFromUpload = {
  file: any
  fileList: any[]
}

export const FormGroupUpload: FC<Props> = ({
  formik,
  fieldName,
  progress,
  isSingleFile,
  text,
  maxWidthFileUpload,
  files,
  ...formGroupProps
}) => {
  const error = formik.errors[fieldName]
  const currentFiles: RespFile[] = !!files?.length ? files : []
  const [fileList, setFileList] = useState<RespFile[]>(currentFiles)

  const addFile = async (data: FileFromUpload) => {
    try {
      const form = new FormData()
      form.append('uploaded_file', data.file)
      const newFile = await apiClient.post<RespFile>(endpoints.files(), form)

      if (isSingleFile) {
        setFileList([newFile])
      } else {
        setFileList((files) => [newFile, ...files])
      }
    } catch (error) {}
  }

  useEffect(() => {
    const newList = fileList.map((file) => file.id)
    formik.setFieldValue(fieldName, newList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList])

  const removeFile = (id) => {
    const newFiles = fileList.filter((file) => file.id != id)
    setFileList(newFiles)
  }
  // If the form submission is successful, clear the upload.
  // This is necessary because the upload component will not be cleared by formik.resetForm()
  useEffect(() => {
    if (progress === Progress.SUCCESS) setFileList([])
  }, [progress])

  return (
    <FormGroup error={error} {...formGroupProps}>
      <AUploadFiles
        maxWidth={maxWidthFileUpload}
        hint={isSingleFile ? t('common:single_file') : undefined}
        text={text}
        maxCountFiles={isSingleFile ? 1 : undefined}
        addFile={addFile}
        fileList={fileList}
        removeFile={removeFile}
      />
    </FormGroup>
  )
}
