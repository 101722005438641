import { ResponsiveModal } from 'views/components/ResponsiveModal/ResponsiveModal'
import { useAFormHandler } from 'analytics/hooks'
import { SharedModalContent, SharedModalInputs } from 'views/components/SharedModal'
import { User } from 'modules/domain/user/types'
import { generatePath, useNavigate } from 'react-router'
import { apiClient } from 'modules/utils/httpClient'
import DealRoutes from 'views/pages/Deal/routes'
import { Deal } from 'modules/domain/deal/types'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFormik } from 'formik'
import { BidTypes, USBid } from 'modules/domain/bid/types'
import { BidControl } from './BidControl'
import { CreateDealFields } from './types'
import { QuantityInput } from 'views/components/QuantityInput/QuantityInput'
import { labelDistance } from 'modules/utils/numbers/formatDistance'
import { FormInput } from 'views/components/form/FormInput'
import { getPriceLogisticsLabel, labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import { ShortFormControls } from 'views/styled/common'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import { useProgress } from 'hooks/useProgress'
import { CreateDealSkeleton } from './CreateDealSkeleton'
import { Progress } from '@agro-club/agroclub-shared'
import { UserControl } from './UserControl'
import { FormMarketZone } from 'views/components/form/FormMarketZone/FormMarketZone'

type ParamsResponse = {
  price_logistics: string
  logistics_rate: string
  distance: string
  margin_per_kg: string
}

type Props = {
  bid: USBid
  onClose: () => void
}

const AGRO_CLUB_SELLER_ID = '100264'
const AGRO_CLUB_BUYER_ID = '100265'

export const CreateDealFromBidModal: FC<Props> = ({ bid, onClose }) => {
  bid?.bid_type === BidTypes.sale
  const { t } = useTranslation(['bid', 'address'])
  const navigate = useNavigate()

  const [counterPartUser, setCounterPartUser] = useState<User>()
  const { formProgress, formHandler } = useAFormHandler('newDeal')
  const formik = useFormik<CreateDealFields>({
    initialValues: {
      distance: null,
      price_logistics: null,
      counter_bid_id: null,
      quantity: bid.quantity,
      user: bid.bid_type === BidTypes.sale ? AGRO_CLUB_BUYER_ID : AGRO_CLUB_SELLER_ID,
      margin_per_kg: null,
      market_zone: null,
    },
    onSubmit: formHandler(
      async () => {
        const data = {
          price_logistics: formik.values.price_logistics,
          purchase_bid_id: bid.bid_type === BidTypes.sale ? formik.values.counter_bid_id : bid.id,
          sale_bid_id: bid.bid_type === BidTypes.sale ? bid.id : formik.values.counter_bid_id,
          quantity: formik.values.quantity,
          distance: formik.values.distance,
        }
        const response = await apiClient.post<Deal>(endpoints.dealsManual(), data)
        if (response?.id) {
          navigate(generatePath(DealRoutes.Details, { id: response.id }))
        }
      },
      {
        onError: (error) => {
          const { errors } = error
          const bidError = errors.sale_bid_id?.[0] || errors.purchase_bid_id?.[0]
          if (bidError) {
            formik.setErrors({ ...errors, counter_bid_id: bidError })
          } else {
            formik.setErrors(errors)
          }
        },
      },
    ),
  })

  const [dealParamsProgress, fetchDealParams] = useProgress(async () => {
    if (formik.values.counter_bid_id) {
      const data = {
        purchase_bid_id: bid.bid_type === BidTypes.sale ? formik.values.counter_bid_id : bid.id,
        sale_bid_id: bid.bid_type === BidTypes.sale ? bid.id : formik.values.counter_bid_id,
      }

      try {
        const response: ParamsResponse = await apiClient.get(endpoints.calcLogisticParams(), data)
        formik.setFieldValue('price_logistics', response.price_logistics)
        formik.setFieldValue('logistics_rate', response.logistics_rate)
        formik.setFieldValue('distance', response.distance)
        formik.setFieldValue('margin_per_kg', response.margin_per_kg)
      } catch (error: any) {
        // TODO check type
        const { errors } = error
        formik.setErrors(errors)
      }
    }
  })

  useEffect(() => {
    fetchDealParams()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.counter_bid_id])

  return (
    <>
      <ResponsiveModal
        onConfirm={() => formik.submitForm()}
        confirmButtonText={t('common:save')}
        progress={formProgress}
        headerHeight={149}
        onClose={onClose}
        id="bidCloning"
        title={t('createDeal')}
        size="large"
      >
        <SharedModalContent>
          <SharedModalInputs>
            <UserControl formik={formik} setUser={setCounterPartUser} bid={bid} />
            <FormMarketZone formik={formik} />
            <BidControl formik={formik} product={bid.product} symbol={bid.symbol} user={counterPartUser} />
            <SharedModalSecondContent>
              <SharedModalSubTitle>{t('dealDetail')}</SharedModalSubTitle>
              <ShortFormControls>
                <QuantityInput fieldName="quantity" formik={formik} label={t('common:quantity')} />
                {dealParamsProgress === Progress.WORK ? (
                  <CreateDealSkeleton />
                ) : (
                  <>
                    <FormInput
                      formik={formik}
                      field="distance"
                      label={labelDistance(t('createDealModal.distance'))}
                      type="number"
                      disabled={!formik.values.counter_bid_id}
                    />
                    <FormInput
                      formik={formik}
                      field="price_logistics"
                      label={labelPriceLogistics(getPriceLogisticsLabel())}
                      type="number"
                      disabled={!formik.values.counter_bid_id}
                    />
                    <FormInput
                      label={`${labelTarif(t('createDealModal.margin_per_kg'))}`}
                      field="margin_per_kg"
                      disabled
                      formik={formik}
                      type="number"
                    />
                  </>
                )}
              </ShortFormControls>
            </SharedModalSecondContent>
          </SharedModalInputs>
        </SharedModalContent>
      </ResponsiveModal>
    </>
  )
}
