import { DealStatusTypes, MarginsforDeals } from 'modules/domain/deal/types'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { AntTooltip, Text } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { isRussia } from 'env'
import { FC } from 'react'

type MarginType = {
  status: DealStatusTypes
  margins: MarginsforDeals
  revenues?: MarginsforDeals
  sum?: number | undefined
  sum2?: number | undefined
}

export const MoneyBlock = styled.div`
  padding: 4px 16px 0 0;
  display: flex;
  flex-direction: column;
`
const Margin = styled.div`
  display: flex;
  gap: 0 4px;
`
const ShipmentComplete = styled.div<{ isColumn: boolean }>`
  display: flex;
  flex-direction: ${({ isColumn }) => (isColumn ? 'column' : 'row')};
  justify-content: space-between;
`

export const DataMargin: FC<MarginType> = ({ status, margins, revenues }) => {
  const { t } = useTranslation('deal')

  const formatMarginText = (statusKey: DealStatusTypes) => {
    const marginValue = formatPrice(Math.round(margins.margins[statusKey]), true, false)
    const weightedAverageMargin = margins.weighted_average_margins
      ? ` / ${formatNumber(margins.weighted_average_margins[statusKey])}%`
      : ''

    return (
      <Margin>
        <span>{marginValue}</span>
        {weightedAverageMargin && (
          <AntTooltip placement="top" variant="secondary" tooltipContent={t('weighted_average_percentage')}>
            {weightedAverageMargin}
          </AntTooltip>
        )}
      </Margin>
    )
  }

  const RevenueComponent = () =>
    revenues && (
      <Margin>
        <Text typography="bodyMedium" color="secondary">
          {t('revenue')}
        </Text>
        <Text typography="bodyMedium">{formatPrice(Math.round(revenues[status]), true, false)}</Text>
      </Margin>
    )

  const MarginComponent = ({ status, label }: { status: DealStatusTypes; label: string }) => (
    <Margin>
      <Text typography="bodyMedium" color="secondary">
        {label}
      </Text>
      <Text typography="bodyMedium">{formatMarginText(status)}</Text>
    </Margin>
  )

  const marginComponents = {
    [DealStatusTypes.CONTRACT_SIGNED]: <MarginComponent status={status} label={t('signed')} />,
    [DealStatusTypes.PAYMENT_SHIPMENT]: <MarginComponent status={status} label={t('margin_on_way')} />,
    [DealStatusTypes.PICK_UP_COMPLETE]: <MarginComponent status={status} label={t('margin_on_way')} />,
    [DealStatusTypes.SHIPMENT_COMPLETE]: (
      <ShipmentComplete isColumn={isRussia}>
        <MarginComponent status={status} label={t('fact')} />
        <MarginComponent status={DealStatusTypes.EXPECTED_MARGIN} label={t('was')} />
      </ShipmentComplete>
    ),
    default: <MarginComponent status={status} label={t('margin')} />,
  }

  return (
    <MoneyBlock>
      <RevenueComponent />
      {marginComponents[status] || marginComponents.default}
    </MoneyBlock>
  )
}
