import { refetchFunc } from 'modules/domain/common/hooks'
import { isPurchaseBid } from 'modules/domain/bid/utils'
import { BidDetailActions } from './BidDetailActions/BidDetailActions'
import { Card } from 'views/components/Card/Card'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { BidTypes } from 'modules/domain/bid/types'
import styled from 'styled-components'
import { isBrazil } from 'env'
import { FC, ReactNode } from 'react'
import { getDealBid } from 'modules/domain/deal/utils'
import { DealPartyChangeData } from '../../hooks/useDealPartyChange'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'

const TitleWrapper = styled.div<{ isControlsVisible: boolean }>`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  @media (max-width: ${defaultTheme.queries.mobile}) {
    flex-direction: row-reverse;
  }
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0 4px;
`

type Props = {
  refetch: refetchFunc
  deal: Deal
  bidType: BidTypes
  children?: ReactNode
  partyChanger: DealPartyChangeData
}

export const BidDetailTitle: FC<Props> = ({ refetch, deal, bidType, partyChanger, children = null }) => {
  const { t } = useTranslation('deal')

  const bid = getDealBid(deal, bidType)
  const title = isPurchaseBid(bid) ? t('buyer') : t('seller')
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })
  return (
    <TitleWrapper isControlsVisible={isBrazil}>
      <Header>
        {!isMobile && <Card.Title>{title}</Card.Title>}

        <ActionsWrapper>
          <BidDetailActions bidType={bidType} partyChanger={partyChanger} refetch={refetch} deal={deal} />
        </ActionsWrapper>
      </Header>
      {children}
    </TitleWrapper>
  )
}
