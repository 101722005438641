import { defaultTheme, Text, TypographyType } from '@agro-club/agroclub-shared'
import { FC } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div<{ marginBottom: string; forForm: boolean }>`
  display: flex;
  align-items: center;
  gap: 9px;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  padding-right: ${({ forForm }) => (forForm ? '20px' : '0')};
`

export const Hr = styled.div`
  border-top: 1px solid ${defaultTheme.color.secondary50};
  width: 100%;
  margin: 10px 0;
`

type Props = {
  text: string
  marginBottom?: string
  typography?: TypographyType
  forForm?: boolean
}

export const HrWithHeader: FC<Props> = ({ text, marginBottom = '0', typography = 'accentSmall', forForm = true }) => (
  <Wrapper marginBottom={marginBottom} forForm={forForm}>
    <Hr />
    <Text typography={typography} style={{ whiteSpace: 'nowrap', padding: '4px 0px' }}>
      {text}
    </Text>
    <Hr />
  </Wrapper>
)
