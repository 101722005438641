import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Checkbox } from '@agro-club/agroclub-shared'
import { endpoints } from 'modules/endpoints'
import { AddTrailerModal } from 'views/pages/LogisticsKR/components/TrailerModals/AddTrailerModal'
import FormSelectShared from 'views/components/FormSelectShared'
import styled from 'styled-components'
import { MarginWrapper } from '../AddDraftTripModal/styled'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  carrierId: string
  formik: FormikFieldsType
  trailerInfoRequired?: boolean
}

const ShortControlWithAddBtn = styled.div`
  width: 447px;
`

export const TrailerSelect: FC<Props> = ({ carrierId, formik, trailerInfoRequired = false }) => {
  const { t } = useTranslation('logisticsKR')
  const [isAddTrailerOpen, setIsAddTrailerOpen] = useState<boolean>(false)
  const handleClick = useAnalyticsClickHandler('addTrailer')

  return (
    <div>
      <ShortControlWithAddBtn>
        <FormSelectShared
          endpoint={endpoints.LogisticsKR.trailersByCarrier(carrierId)}
          fieldName="trailer"
          label={t('trailerControl.label')}
          getLabel={(dto) => dto.licence_number}
          searchField="licence_number"
          formik={formik}
          placeholder={t('trailerControl.placeholder')}
          addButtonHandler={handleClick(() => setIsAddTrailerOpen(true), { id: carrierId })}
        />
      </ShortControlWithAddBtn>

      <MarginWrapper>
        {trailerInfoRequired && (
          <Checkbox
            isChecked={formik.values.without_trailer}
            onChange={(_value, isChecked) => formik.setFieldValue('without_trailer', isChecked)}
            label={t('trailerControl.without_trailer')}
          />
        )}
      </MarginWrapper>
      {isAddTrailerOpen && (
        <AddTrailerModal
          carrierId={carrierId}
          setIsOpen={setIsAddTrailerOpen}
          onSuccess={(id) => formik.setFieldValue('trailer', id)}
        />
      )}
    </div>
  )
}
