import { createContext, useContext } from 'react'
import { endpoints } from 'modules/endpoints'
import { useParams } from 'react-router'
import { refetchFunc, useSingleEntity } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'

interface DealContextType {
  deal: Deal
  dealRefetch: refetchFunc
}

export const DealContext = createContext<DealContextType>({
  // we dont allow deal be empty to avoid existings checks
  deal: {} as Deal,
  dealRefetch: async () => {
    return undefined
  },
})

export const useDealContext = () => {
  const context = useContext(DealContext)
  if (!context.deal.id) {
    throw new Error('DealContext outside of Provider')
  }

  return context
}

export function DealProvider({ children }: any) {
  const { id } = useParams()

  const [, deal, dealRefetch] = useSingleEntity<Deal>(endpoints.deal(id))

  const loading = () => <ItemLoadingLayout id={id} />

  if (!deal) {
    return loading()
  }

  return <DealContext.Provider value={{ deal, dealRefetch }}>{children}</DealContext.Provider>
}
