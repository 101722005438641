import { BodySizes } from '@agro-club/agroclub-shared'
import { CompanyShort } from 'modules/domain/company/types'
import CompanyRoutes from '../../pages/Company/routes'
import { FC, ReactNode } from 'react'

import { generatePath } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ARouterLink } from '../Analytics'

type Props = {
  company: {
    id: CompanyShort['id']
    name: CompanyShort['name']
  }
  bodysize?: BodySizes
  children?: ReactNode
  target?: string
}

export const CompanyLink: FC<Props> = ({ company, bodysize, children, target }) => {
  const { t } = useTranslation('company')
  return (
    <ARouterLink
      eventName="company"
      itemId={company.id}
      bodysize={bodysize}
      to={generatePath(CompanyRoutes.Details, { id: company.id })}
      target={target}
      title={company.name}
    >
      {children || company.name || t('list.emptyCompanyName')}
    </ARouterLink>
  )
}
