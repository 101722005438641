import { FormGroup, Icons, Input, Progress, defaultTheme } from '@agro-club/agroclub-shared'
import { labelPriceLogistics } from 'modules/utils/numbers/formatPriceLogistics'
import HorizontalKeyValue from 'views/components/KeyValue/HorizontalKeyValue'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import FormSelectShared from 'views/components/FormSelectShared'
import { AButton } from 'views/components/Analytics'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import styled from 'styled-components'
import { FC, useEffect, useRef } from 'react'
import { labelDistance } from 'modules/utils/numbers/formatDistance'
import { FormikFieldsType } from 'modules/domain/types'

export const BlockForm = styled.div`
  transition: opacity ease-in 0.2s;
`
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px 0;
  margin-top: 8px;
`
export const InputsWrapper = styled.div`
  background: ${defaultTheme.color.primary8a};
  border: 1px solid ${defaultTheme.color.primary16a};
  padding: 12px 0 12px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px 0;
`

export const ShortInput = styled(Input)`
  width: 94px;
  input {
    height: 32px;
    padding: 5px 12px;
  }
`
export const Button = styled(AButton)`
  width: 100%;
`

type Props = {
  formik: FormikFieldsType
  progress: Progress
  withExecutors?: boolean
  partyChanging?: boolean
}

const executors = [
  { type: 'user-coordinators', field: 'user_coordinator' },
  { type: 'deal-coordinators', field: 'deal_coordinator' },
  { type: 'logisticians', field: 'logisticians', multiple: true },
  { type: 'traders', field: 'trader' },
]

export const RematchForm: FC<Props> = ({ formik, progress, withExecutors = false, partyChanging }) => {
  const { t } = useTranslation()
  const btnSubmitCaption = partyChanging ? t('bid:contracts.changeContract') : t('deal:rematch.createDeal')
  const icon = partyChanging ? Icons.IconReplace : Icons.IconDeals
  const ref = useRef<HTMLInputElement>()
  useEffect(() => {
    ref?.current?.focus()
  }, [])

  return (
    <BlockForm>
      <Form onSubmit={(e) => formik.handleSubmit(e)}>
        <InputsWrapper>
          <HorizontalKeyValue label={labelQuantity()}>
            <FormGroup error={formik.errors.quantity} required>
              <ShortInput {...formik.getFieldProps('quantity')} invalid={!!formik.errors.quantity} ref={ref} />
            </FormGroup>
          </HorizontalKeyValue>
          <HorizontalKeyValue label={labelPriceLogistics(t('deal:rematch.costOfDelivery'))}>
            <FormGroup error={formik.errors.price_logistics} required>
              <ShortInput {...formik.getFieldProps('price_logistics')} invalid={!!formik.errors.price_logistics} />
            </FormGroup>
          </HorizontalKeyValue>
          <HorizontalKeyValue label={labelDistance(t('deal:fields.distance'))}>
            <FormGroup error={formik.errors.distance} required>
              <ShortInput {...formik.getFieldProps('distance')} invalid={!!formik.errors.distance} />
            </FormGroup>
          </HorizontalKeyValue>
        </InputsWrapper>
        {withExecutors && (
          <InputsWrapper>
            {executors.map((item) => {
              return (
                <FormSelectShared
                  onClear={() => formik.setFieldValue(item.field, null)}
                  placeholder={t('fieldsLabels:select_assignee')}
                  endpoint={endpoints.executorsByType(item.type)}
                  label={t(`executors:${item.field}`)}
                  getLabel={(dto) => dto.full_name}
                  multiple={item.multiple}
                  fieldName={item.field}
                  transparent={false}
                  searchField="search"
                  labelMinWidth={80}
                  key={item.field}
                  formik={formik}
                  size="small"
                />
              )
            })}
          </InputsWrapper>
        )}

        <Button progress={progress} Icon={icon} intent="primary" id="createDeal" type="submit" size="big">
          {btnSubmitCaption}
        </Button>
      </Form>
    </BlockForm>
  )
}
