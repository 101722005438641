import { useAProgress } from 'hooks/useAProgress'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Company } from 'modules/domain/company/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { useTranslation } from 'react-i18next'
import AButton from 'views/components/Analytics/AButton'

interface Props {
  company: Company
  refetchCompany: refetchFunc
}

export const RequestFactoring = ({ company, refetchCompany }: Props) => {
  const { t } = useTranslation('company')
  const [requestFactoringProgress, requestFactoring] = useAProgress(
    () => apiClient.post(endpoints.requestFactoring(company.id)),
    {
      eventName: 'requestFactoring',
      analyticsPayload: {
        id: company.id,
      },
      onSuccess: () => refetchCompany(true),
    },
  )

  if (company.factoring_request) return null

  return (
    <AButton
      intent="secondary"
      id="submit|requestFactoring"
      progress={requestFactoringProgress}
      onClick={requestFactoring}
    >
      {t('requestFactoring')}
    </AButton>
  )
}
