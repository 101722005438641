import { DocumentRequestsRoutes } from 'views/pages/DocumentRequests/routes'
import WarehousesKRRoutes from 'views/pages/LogisticsKR/WarehousesKR/routes'
import DealsKRRoutes from 'views/pages/LogisticsKR/LogisticRequests/routes'
import CustomersKRRoutes from 'views/pages/LogisticsKR/CustomersKR/routes'
import ShipmentsControlRoutes from 'views/pages/ShipmentsControl/routes'
import CarriersKRRoutes from 'views/pages/LogisticsKR/CarriersKR/routes'
import CallStatisticsRoutes from 'views/pages/CallStatistics/routes'
import SpecificationsRoutes from 'views/pages/Specifications/routes'
import CarsKRRoutes from 'views/pages/LogisticsKR/CarsKR/routes'
import BidContractsRoutes from 'views/pages/BidContracts/routes'
import { isBidContractsEnabled, isBrazil, isRussia, isUS } from 'env'
import { ContractsRoutes } from 'views/pages/Contracts/routes'
import RatesRoutes from 'views/pages/LogisticsKR/Rates/routes'
import DispatchingRoutes from 'views/pages/Dispatching/routes'
import CalculatorRoutes from 'views/pages/Сalculator/routes'
import DashboardRoutes from 'views/pages/Dashboard/routes'
import WhatsAppRoutes from 'views/pages/WhatsApp/routes'
import SamplesRoutes from 'views/pages/Samples/routes'
import CompanyRoutes from 'views/pages/Company/routes'
import TasksRoutes from 'views/pages/MyTasks/routes'
import RoutesRoutes from 'views/pages/Routes/routes'
import { Icons } from '@agro-club/agroclub-shared'
import CallsRoutes from 'views/pages/Calls/routes'
import TeamsRoutes from 'views/pages/Teams/routes'
import UsersRoutes from 'views/pages/User/routes'
import DealRoutes from 'views/pages/Deal/routes'
import BidRoutes from 'views/pages/Bid/routes'
import ExportRoutes from 'views/pages/Export/routes'
import { EventModel } from 'modules/socket/types'
import PaymentOrdersRoutes from 'views/pages/LogisticsKR/PaymentOrders/routes'

export const defaultRoute = CompanyRoutes.List

export const OPTIONS = {
  DASHBOARD: {
    key: DashboardRoutes.Root,
    translateKey: 'dashboard',
    icon: <Icons.IconDashboard />,
    access: { permission: 'users.view_menu_dashboard', extra: true },
  },
  CALCULATOR: {
    key: CalculatorRoutes.List,
    translateKey: 'calculator',
    icon: <Icons.IconCalculator />,
    access: { permission: 'users.view_menu_dashboard', extra: isBidContractsEnabled },
  },
  MY_TASKS: {
    key: TasksRoutes.List,
    translateKey: 'myTasks',
    icon: <Icons.IconMatch />,
    access: { permission: 'users.view_menu_tasks', extra: true },
    eventModel: 'task' as const,
  },
  TEAMS: {
    key: TeamsRoutes.List,
    translateKey: 'teams',
    icon: <Icons.IconTeams />,
    access: { permission: 'users.view_menu_teams', extra: true },
  },
  COMPANIES: {
    key: CompanyRoutes.List,
    translateKey: 'companies',
    icon: <Icons.IconCompanies />,
    access: { permission: 'users.view_menu_companies', extra: true },
  },

  USERS: {
    key: UsersRoutes.List,
    translateKey: 'users',
    icon: <Icons.IconRetailer />,
    access: { permission: 'users.view_menu_users', extra: true },
  },
  BIDS: {
    key: BidRoutes.List,
    translateKey: 'bids',
    icon: <Icons.IconDescription />,
    access: { permission: 'users.view_menu_bids', extra: true },
  },
  BID_CONTRACTS: {
    key: BidContractsRoutes.List,
    translateKey: 'bidContracts',
    icon: <Icons.IconContract />,
    access: { permission: 'users.view_menu_bids', extra: isBidContractsEnabled },
  },
  DEALS: {
    key: DealRoutes.List,
    translateKey: 'deals',
    icon: <Icons.IconDeals />,
    access: { permission: 'users.view_menu_deals', extra: true },
  },

  LOGISTICS_REQUESTS: {
    key: DealsKRRoutes.List,
    translateKey: 'logisticsRequests',
    icon: <Icons.IconDescription />,
    access: { permission: 'new_logistics.view_logisticsrequest', extra: true },
    eventModel: ['tripoffer', 'tripdocument'] as EventModel[],
  },
  CARRIERS: {
    key: CarriersKRRoutes.List,
    icon: <Icons.IconRetailer />,
    translateKey: 'carriers',
    access: { permission: 'new_logistics.view_carrier', extra: true },
  },
  CARS: {
    key: CarsKRRoutes.List,
    translateKey: 'cars',
    icon: <Icons.IconShipping />,
    access: { permission: 'new_logistics.view_car', extra: true },
  },
  RATES: {
    key: RatesRoutes.List,
    translateKey: 'rates',
    icon: <Icons.IconCalculator />,
    access: { permission: 'logistics.view_logisticsrate', extra: !isUS },
  },
  PAYMENT_ORDERS: {
    key: PaymentOrdersRoutes.List,
    translateKey: 'paymentOrders',
    icon: <Icons.IconCalculator />,
    access: { permission: 'new_logistics.view_paymentorder', extra: isRussia },
  },
  ROUTES: {
    key: RoutesRoutes.List,
    translateKey: 'routes',
    icon: <Icons.IconCalculator />,
    access: { permission: 'logistics.view_referenceroute', extra: !isRussia },
  },
  CUSTOMERS: {
    key: CustomersKRRoutes.List,
    translateKey: 'customers',
    icon: <Icons.IconRetailer />,
    access: { permission: 'new_logistics.view_customer', extra: true },
  },
  WAREHOUSES: {
    key: WarehousesKRRoutes.List,
    translateKey: 'warehouses',
    icon: <Icons.IconAddress />,
    access: { permission: 'new_logistics.view_loadingwarehouse', extra: true },
  },
  SHIPMENTS_CONTROL: {
    key: ShipmentsControlRoutes.List,
    translateKey: 'shipmentsControl',
    icon: <Icons.IconShippingDone />,
    access: { permission: 'users.view_menu_deals_dispatching', extra: isBrazil },
  },
  DISPATCHING: {
    key: DispatchingRoutes.List,
    translateKey: 'dispatching',
    icon: <Icons.IconShippingDone />,
    access: { permission: 'users.view_menu_deals_dispatching', extra: !isBrazil },
  },
  EXPORT: {
    key: ExportRoutes.List,
    translateKey: 'export',
    icon: <Icons.IconShip />,
    access: { permission: 'export.view_exportcontract', extra: isRussia },
  },
  CALLS_LIST: {
    key: CallsRoutes.List,
    translateKey: 'callsList',
    icon: <Icons.IconPhone />,
    access: { permission: 'users.view_menu_calls_list', extra: true },
  },
  WHATS_APP: {
    key: WhatsAppRoutes.List,
    translateKey: 'whatsApp',
    icon: <Icons.IconWhatsapp />,
    access: { permission: 'users.view_menu_calls_statistics', extra: isUS },
  },
  CALL_STATISTICS: {
    key: CallStatisticsRoutes.List,
    translateKey: 'callStatistics',
    icon: <Icons.IconPhoneOut />,
    access: { permission: 'users.view_menu_calls_statistics', extra: true },
  },
  SPECIFICATIONS: {
    key: SpecificationsRoutes.List,
    icon: <Icons.IconOrders1 />,
    translateKey: 'specifications',
    access: { permission: 'users.view_menu_specifications', extra: true },
  },
  SAMPLES: {
    key: SamplesRoutes.List,
    icon: <Icons.IconSampling />,
    translateKey: 'samples',
    access: { permission: 'samples.view_sample', extra: true },
  },

  DOCUMENTS_LIST: {
    key: DocumentRequestsRoutes.List,
    translateKey: 'documentsList',
    icon: <Icons.IconAttach />,
    access: { permission: 'users.view_menu_documents_requests', extra: true },
    eventModel: 'documentsrequest' as const,
  },
  CONTRACTS: {
    key: ContractsRoutes.List,
    translateKey: 'contracts',
    icon: <Icons.IconSign />,
    access: { permission: 'users.view_menu_documents_requests', extra: true },
    eventModel: 'salecontractrequest' as const,
  },
}
