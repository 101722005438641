import { DealValidationErrors } from 'views/components/ChangeDealStatus/DealStatusModal/DealValidationInfo'
import { DocumentRequestControl } from '../DocumentRequestControl/DocumentRequestControl'
import { BidCloningButton } from '../DealManagement/BidCloningModal/BidCloningButton'
import { AntTag, Icons, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { DealExecutorsBlock } from '../DealExecutorsBlock/DealExecutorsBlock'
import { DealDetailBlock } from '../DealDetailBlock/DealDetailBlock'
import { StatusSelect } from '../DealManagement/StatusSelect'
import { FuturesPeriodTag } from 'views/components/Tags'
import { isDealClosed } from 'modules/domain/deal/utils'
import { CloseDeal } from '../DealManagement/CloseDeal'
import { DealStatusLabel } from '../DealStatusLabel'
import { SetTask } from '../DealManagement/SetTask'
import { Declaration } from 'modules/domain/types'
import * as Layout from 'views/layouts/NewLayout'
import { AccordionModal } from './AccordionModal'
import { useDealContext } from '../DealContext'
import useFormatDate from 'hooks/useFormatDate'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { Bids } from '../Bids/Bids'
import { FC, useState } from 'react'

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 16px;
`
const Controls = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px 0;
  overflow-y: auto;
  scrollbar-width: none;
`
const CenterBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px 0;
`
const CollapseBtn = styled.div`
  position: fixed;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background: ${defaultTheme.color.primary600};
  bottom: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  right: 24px;
  box-shadow: 0px 4px 12px 0px rgba(39, 45, 51, 0.12);
  svg {
    path {
      fill: ${defaultTheme.color.backgroundSecondary};
    }
  }
`

type Props = {
  declaration?: Declaration
}
export const DetailMobile: FC<Props> = ({ declaration }) => {
  const { deal } = useDealContext()
  const { t } = useTranslation('deal')
  const canMoveNextStatus = !Object.values(deal.next_status_validation).some((v) => v.missing)
  const formatDate = useFormatDate()
  const navigate = useNavigate()
  const [openAccordion, setOpenAccordion] = useState(false)
  return (
    <>
      <CollapseBtn onClick={() => setOpenAccordion(true)}>
        <Icons.IconHidemenu />
      </CollapseBtn>
      <Layout.WrapperContent>
        <Wrapper>
          <Header>
            <Icons.IconArrowLeftM onClick={() => navigate(-1)} />
            <CenterBlock>
              {isDealClosed(deal) && (
                <AntTag color="grey">{`${t('isClosed')} / ${t(`closed_reasons.${deal.closed_reason}`)}`}</AntTag>
              )}
              <DealStatusLabel status={deal.status} />
              <FuturesPeriodTag value={deal} />

              <Text typography="titleH4">{`${deal.product.title}, ${deal.season?.name || ''}`}</Text>
              <Text typography="bodySmall">
                {t('common:detailPageSubTitle', { id: deal.id, created: formatDate(deal.created, true) })}
              </Text>
            </CenterBlock>
            <div>
              {!canMoveNextStatus && !isDealClosed(deal) && (
                <DealValidationErrors validationInfo={deal.next_status_validation} />
              )}
            </div>
          </Header>

          {!isDealClosed(deal) && (
            <Controls>
              <StatusSelect />
              <BidCloningButton deal={deal} />
              <SetTask deal={deal} />
              <CloseDeal deal={deal} />
            </Controls>
          )}

          <DealExecutorsBlock />
          <DealDetailBlock />
          <DocumentRequestControl declaration={declaration} />
          <Bids />
        </Wrapper>
        {openAccordion && <AccordionModal deal={deal} onClose={() => setOpenAccordion(false)} />}
      </Layout.WrapperContent>
    </>
  )
}
