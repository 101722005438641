import { AntSwitch, Icons, Progress, Spinner, Text } from '@agro-club/agroclub-shared'
import { useAProgress } from 'hooks/useAProgress'
import { ACCORDION_ITEM_PAGE_SIZE } from 'modules/constants'
import { refetchFunc, useTableData } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { Export, ExportStatus } from 'modules/domain/export/types'
import { ListRequestParams } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { AButton } from 'views/components/Analytics'
import { BorderedItem } from 'views/components/BorderedItem/BorderedItem'
import { Pagination } from 'views/components/Pagination/Pagination'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { AddExport } from 'views/pages/Export/components/AddExport'
import { SpinnerWrapper } from 'views/styled/common'
import { ConfirmModal } from './ConfrimModal'
import { ItemTable } from './ItemTable'

const ItemContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 12px;
`
type Props = {
  deal: Deal
  refetchDeal: refetchFunc
  setSummary: (arg: boolean) => void
}

export const ExportBlock: FC<Props> = ({ deal, refetchDeal, setSummary }) => {
  const { t } = useTranslation('export')

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
    filter: { ...defaultListRequestParams.filter, status: ExportStatus.active },
    pageSize: ACCORDION_ITEM_PAGE_SIZE,
  })

  const { progress, total, data = [], refetch } = useTableData<Export>(endpoints.export(), listRequestParams, true)

  const [isConfirmModal, setConfirmModal] = useState(false)
  const [isAddModal, setAddModal] = useState(false)
  const [currentIdContract, setCurrentIdContract] = useState('')

  const refetchAll = async () => {
    await refetchDeal()
    await refetch()
  }

  const [unlinkProgress, unlinkHandler] = useAProgress(
    () => apiClient.put(endpoints.attachContract(deal.id), { export_contract: null, confirmation: true }),
    {
      eventName: 'unlinkContract',
      onSuccess: async () => {
        await refetchAll()
      },
    },
  )

  const onChangeSwitch = (v, id) => {
    if (!v) {
      unlinkHandler()
      return
    }
    setCurrentIdContract(id)
    setConfirmModal(v)
  }

  useEffect(() => {
    if (progress === Progress.SUCCESS) {
      setSummary(!!data.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.length])

  return (
    <>
      {progress === Progress.WORK ? (
        <SpinnerWrapper>
          <Spinner />
        </SpinnerWrapper>
      ) : (
        <div>
          {!!data.length ? (
            data.map((item) => (
              <BorderedItem hover={false} key={item.id}>
                <ItemContent>
                  <ItemTable exportData={item} />
                  <AntSwitch
                    loading={unlinkProgress === Progress.WORK}
                    onChange={(v) => onChangeSwitch(v, item.id)}
                    checked={deal.export_contract_id === item.id}
                  />
                </ItemContent>
              </BorderedItem>
            ))
          ) : (
            <BorderedItem hover={false} justifyContent="center">
              <Text color="secondary">{t('common:not_found')}</Text>
            </BorderedItem>
          )}
        </div>
      )}
      <Footer>
        <AButton intent="approve" id="addExportContract" Icon={Icons.IconAdd} onClick={() => setAddModal(true)}>
          {t('common:add')}
        </AButton>

        <Pagination
          disableMargin
          setCurrPage={(page) => setListRequestParams((prev) => ({ ...prev, page }))}
          currPage={listRequestParams.page}
          total={total}
        />

        {isConfirmModal && (
          <ConfirmModal
            onClose={() => setConfirmModal(false)}
            contractId={currentIdContract}
            refetch={refetchAll}
            deal={deal}
          />
        )}
        {isAddModal && <AddExport onClose={() => setAddModal(false)} refetch={refetchAll} />}
      </Footer>
    </>
  )
}
