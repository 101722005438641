import { TableWrapper } from 'views/pages/User/UserDetail/UserRightPanel/RightBlockTable/styled'
import { Export, CurrencySign, ExportDealType } from 'modules/domain/export/types'
import { AntdTable, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import formatNumber from 'modules/utils/numbers/formatNumber'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { ExportFiles } from '../components/ExportFiles'
import useColumns from '../components/useColumns'
import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { Price } from '../components/Price'
import { DASH } from 'modules/constants'
import styled from 'styled-components'
import { Rates } from './Rates'
import { FC } from 'react'

const CostBlock = styled.div`
  padding: 16px;
  background: ${defaultTheme.color.backgroundPrimary};
  width: 432px;
  border-radius: 8px;
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 12px 0;
`

const NameCost = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Hr = styled.div`
  height: 1px;
  background: ${defaultTheme.color.secondary100};
`
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  padding-bottom: 88px;
`

type Props = {
  exportData: Export
}

export const ExportLeftBlock: FC<Props> = ({ exportData }) => {
  const { t } = useTranslation('export')

  const columns = useColumns()
  const [progress, deals] = useSingleEntity<ExportDealType[]>(endpoints.exportDeals(exportData.id))

  return (
    <Wrapper>
      <Card.Container>
        <Card.Grid cols={2} maxColumnWidth={'550px'}>
          <Card.GapableContent>
            <Card.Title>{t('commonInfo')}</Card.Title>
            <Card.Grid cols={2} maxColumnWidth={'263px'}>
              <KeyValue label={t('contractNumber')}>{exportData.number || DASH}</KeyValue>
              <KeyValue label={t('unk')}>{exportData.unique_contract_number || DASH}</KeyValue>
              <KeyValue label={t('contractDate')}>{exportData.contract_date || DASH}</KeyValue>
              <KeyValue label={t('billLadingDate')}>{exportData.consignment_date || DASH}</KeyValue>
              <KeyValue label={t('viaDubai')}>{exportData.through_dubai ? t('common:yes') : t('common:no')}</KeyValue>
              <div></div>
              <KeyValue label={t('buyer')}>{exportData?.purchaser?.name || DASH}</KeyValue>
              <div></div>
              <KeyValue label={t('vesselName')}>{exportData?.ship?.name || DASH}</KeyValue>
              <KeyValue label={t('shipowner')}>{exportData.ship_owner?.name || DASH}</KeyValue>
              <KeyValue label={t('transshipment')}>{exportData.warehouse?.title || DASH}</KeyValue>
              <div></div>
              <KeyValue label={t('exportBroker')}>{exportData.export_broker?.name || DASH}</KeyValue>
              <KeyValue label={t('freightBroker')}>{exportData.freight_broker?.name || DASH}</KeyValue>
              <KeyValue label={t('insurance')}>{exportData.insurance?.name || DASH}</KeyValue>
              <KeyValue label={t('surveyor')}>{exportData.surveyor?.name || DASH}</KeyValue>
            </Card.Grid>

            <ExportFiles id={exportData.id} />
          </Card.GapableContent>
          <CostBlock>
            <NameCost>
              <Text typography="bodyMedium">{t('contractValue')}</Text>
              <Text typography="titleH4">
                {`${formatNumber(exportData.price, true)} ${t('formatPrice', {
                  currency: CurrencySign[exportData.price_currency],
                })}`}
              </Text>
            </NameCost>
            <Rates exportData={exportData} />

            <Price
              localizedPrice={exportData.through_dubai_localized_price}
              currency={exportData.through_dubai_price_currency}
              price={exportData.through_dubai_price}
              label={t('priceThroughDubai')}
            />

            <Price
              localizedPrice={exportData.freight_cost_localized_price}
              currency={exportData.freight_currency}
              price={exportData.freight_cost}
              label={t('freight')}
            />

            <Price
              localizedPrice={exportData.warehouse_cost_localized_price}
              currency={exportData.warehouse_currency}
              price={exportData.warehouse_cost}
              label={t('transshipment')}
            />

            <Price
              localizedPrice={exportData.storage_cost_localized_price}
              currency={exportData.storage_currency}
              price={exportData.storage_cost}
              label={t('storage')}
            />

            <Price
              localizedPrice={exportData.export_broker_cost_localized_price}
              currency={exportData.export_broker_currency}
              price={exportData.export_broker_cost}
              label={t('broker')}
            />

            <Price
              localizedPrice={exportData.freight_broker_cost_localized_price}
              currency={exportData.freight_broker_currency}
              price={exportData.freight_broker_cost}
              label={t('freightBroker')}
            />

            <Price
              localizedPrice={exportData.documents_cost_localized_price}
              currency={exportData.documents_currency}
              price={exportData.documents_cost}
              label={t('documents')}
            />

            <Price
              localizedPrice={exportData.duty_cost_localized_price}
              currency={exportData.duty_currency}
              price={exportData.duty_cost}
              label={t('toll')}
            />

            <Price
              localizedPrice={exportData.insurance_cost_localized_price}
              currency={exportData.insurance_currency}
              price={exportData.insurance_cost}
              label={t('insurance')}
            />

            <Price
              localizedPrice={exportData.surveyor_cost_localized_price}
              currency={exportData.surveyor_currency}
              price={exportData.surveyor_cost}
              label={t('surveyor')}
            />

            <Hr />
            <NameCost>
              <Text typography="bodyMedium">{t('total')}</Text>
              <Text typography="titleH4">{formatPrice(exportData.total_margin, true)}</Text>
            </NameCost>
          </CostBlock>
        </Card.Grid>
      </Card.Container>
      <TableWrapper>
        <AntdTable columns={columns} data={deals ?? []} progress={progress} rowKey="id" />
      </TableWrapper>
    </Wrapper>
  )
}
