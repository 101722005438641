import { Card } from 'views/components/Card/Card'
import { CardRow, LinkWrapper } from '../styled'
import AExternalLink from 'views/components/Analytics/AExternalLink'
import { ChangeFactoringStatus } from './ChangeFactoringStatus'

import { refetchFunc } from 'modules/domain/common/hooks'
import { Company } from 'modules/domain/company/types'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'

interface Props {
  company: Company
  refetchCompany: refetchFunc
}

export const CompanyFactoringFiles = ({ company, refetchCompany }: Props) => {
  if (!company.factoring_request) return null

  const doc = company.factoring_request.document
  const pdf = company.factoring_request.document_pdf

  return (
    <Card.Container padding="compact" bordered>
      <CardRow>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
          <LinkWrapper>
            <AExternalLink href={doc?.uploaded_file} target="_blank" eventName="viewFactoringFile">
              <TruncatedValue hint={doc?.original_filename}>{doc?.original_filename}</TruncatedValue>
            </AExternalLink>
          </LinkWrapper>
          <LinkWrapper>
            <AExternalLink href={pdf?.uploaded_file} target="_blank" eventName="viewFactoringFilePdf">
              <TruncatedValue hint={pdf?.original_filename}>{pdf?.original_filename}</TruncatedValue>
            </AExternalLink>
          </LinkWrapper>
        </div>
        <ChangeFactoringStatus company={company} refetchCompany={refetchCompany} />
      </CardRow>
    </Card.Container>
  )
}
