import { AntTooltip, Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { FC } from 'react'
import { FactoringStatus } from 'modules/domain/company/types'
import { isFactoringAvailable } from 'env'
import { factoringStatusColors } from 'views/pages/Company/constants'

type Props = {
  isVisible?: boolean
  text: string
  children?: React.ReactNode
}

type PropsIcon = {
  isVisible?: boolean
  size?: 'large' | 'small'
}

const IconWrapper = styled.div<{ $iconColor?: string; size?: 'large' | 'small' }>`
  display: inline;
  width: ${({ size }) => (size === 'large' ? '24px' : '16px')};
  height: ${({ size }) => (size === 'large' ? '24px' : '16px')};

  svg {
    width: inherit;
    height: inherit;

    path {
      fill: ${({ $iconColor }) => $iconColor};
    }
  }
`

export const TooltipIcon: FC<Props> = ({ isVisible = false, text, children }) => {
  if (!isVisible) return null

  return (
    <AntTooltip tooltipContent={text} variant="secondary" placement="top">
      {children}
    </AntTooltip>
  )
}

export const KeyUserIcon: FC<PropsIcon> = ({ isVisible, size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={isVisible} text={t('key_user')}>
      <IconWrapper $iconColor={defaultTheme.color.accentApprove600} size={size}>
        <Icons.IconDollar />
      </IconWrapper>
    </TooltipIcon>
  )
}

export const VerifiedUserIcon: FC<PropsIcon> = ({ isVisible, size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={isVisible} text={t('verify_user')}>
      <IconWrapper $iconColor={defaultTheme.color.primary600} size={size}>
        <Icons.IconCheckMark />
      </IconWrapper>
    </TooltipIcon>
  )
}

export const NotVerifiedUserIcon: FC<PropsIcon> = ({ isVisible, size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={isVisible} text={t('not_verify_user')}>
      <IconWrapper size={size}>
        <Icons.IconCheckMark />
      </IconWrapper>
    </TooltipIcon>
  )
}

export const BlockedUserIcon: FC<PropsIcon> = ({ size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={true} text={t('blocked')}>
      <IconWrapper size={size} $iconColor={defaultTheme.color.accentDestruct800}>
        <Icons.IconCancelled />
      </IconWrapper>
    </TooltipIcon>
  )
}

export const CoordinatorVerifiedUserIcon: FC<PropsIcon> = ({ isVisible, size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={isVisible} text={t('verify_coordinator')}>
      <IconWrapper $iconColor={defaultTheme.color.accentNotify600} size={size}>
        <Icons.IconCheckMark />
      </IconWrapper>
    </TooltipIcon>
  )
}

export const SecurityVerifiedUserIcon: FC<PropsIcon> = ({ isVisible, size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={isVisible} text={t('verify_security')}>
      <IconWrapper $iconColor={defaultTheme.color.accentNotify600} size={size}>
        <Icons.IconDefend />
      </IconWrapper>
    </TooltipIcon>
  )
}

export const KycVerifiedUserIcon: FC<PropsIcon> = ({ isVisible, size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={isVisible} text={t('verify_kyc')}>
      <IconWrapper $iconColor={defaultTheme.color.accentNotify600} size={size}>
        <Icons.IconCrops />
      </IconWrapper>
    </TooltipIcon>
  )
}

export const UserHasDealsIcon: FC<PropsIcon> = ({ isVisible, size }) => {
  const { t } = useTranslation('user')
  return (
    <TooltipIcon isVisible={isVisible} text={t('completed_transactions')}>
      <IconWrapper $iconColor={defaultTheme.color.primary600} size={size}>
        <Icons.IconDeals />
      </IconWrapper>
    </TooltipIcon>
  )
}

interface CompanyFactoringIconProps {
  factoringStatus?: FactoringStatus
}

export const CompanyFactoringIcon = ({ factoringStatus }: CompanyFactoringIconProps) => {
  const { t } = useTranslation('company')

  const availableStatuses =
    factoringStatus === FactoringStatus.in_progress || factoringStatus === FactoringStatus.accepted

  if (!availableStatuses || !isFactoringAvailable) return null

  return (
    <TooltipIcon text={t(`factoringStatuses.${factoringStatus}`)} isVisible>
      <IconWrapper $iconColor={factoringStatusColors[factoringStatus]}>
        <Icons.IconFactoring />
      </IconWrapper>
    </TooltipIcon>
  )
}
