import { defaultTheme } from '@agro-club/agroclub-shared'
import { isBidProductParamsEnabled } from 'env'
import { Bid } from 'modules/domain/bid/types'
import { getMainParameter } from 'modules/utils/helpers'
import { FC } from 'react'
import styled from 'styled-components'

const Product = styled.div`
  display: flex;
  span {
    color: ${defaultTheme.color.secondaryPlaceholder};
  }
`

const ProductCell: FC<Bid> = (props) => {
  const { product, parameters } = props
  return (
    <Product>
      {product.title}
      {isBidProductParamsEnabled ? <span>,&nbsp;{getMainParameter(parameters)}</span> : null}
    </Product>
  )
}

export default ProductCell
