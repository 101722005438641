import { FC } from 'react'

import styled from 'styled-components'
import { Key, KeyValueProps, Value } from './KeyValue'

type FlexColumnGap = 'small' | 'medium'

const Wrapper = styled.div<{ columnGap?: FlexColumnGap }>`
  display: flex;
  align-items: center;
  justify-content: ${({ columnGap }) => (columnGap ? 'normal' : 'space-between')};
  column-gap: ${({ columnGap }) => {
    switch (columnGap) {
      case 'small':
        return '4px'
      case 'medium':
        return '12px'
    }
  }};
`

const HorizontalKeyValue: FC<KeyValueProps & { columnGap?: FlexColumnGap }> = ({
  labelTypo = 'bodyLarge',
  valueTypo = 'bodyLarge',
  columnGap,
  isNumber,
  children,
  label,
}) => {
  return (
    <Wrapper columnGap={columnGap}>
      <Key horizontal typography={labelTypo}>
        {label}
      </Key>

      <Value isNumber={isNumber} typography={valueTypo}>
        {children}
      </Value>
    </Wrapper>
  )
}

export default HorizontalKeyValue
