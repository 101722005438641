import { Card } from 'views/components/Card/Card'
import { useTranslation } from 'react-i18next'
import { AButton, ARouterLink } from 'views/components/Analytics'
import { AntSkeleton, AntTag, Icons, Progress } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { Bid } from 'modules/domain/bid/types'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { SamplesForBid } from 'views/pages/Bid/BidDetail/Bid/types'
import { endpoints } from 'modules/endpoints'
import { AddSample } from 'views/pages/Bid/BidDetail/Bid/Samples/AddSample'
import { useMemo, useState } from 'react'
import { SampleStatus } from 'views/pages/Samples/types'
import useFormatDate from 'hooks/useFormatDate'
import { generatePath } from 'react-router-dom'
import SamplesRoutes from 'views/pages/Samples/routes'
import { FileList } from 'views/FileList/FileList'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const LinkWithTagWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: center;
  margin-bottom: 4px;
`

type SampleItemProps = {
  sample: SamplesForBid
}

const SampleItem = ({ sample }: SampleItemProps) => {
  const { t } = useTranslation('deal')
  const formatDate = useFormatDate()

  return (
    <div>
      <LinkWithTagWrapper>
        <ARouterLink eventName="sample_detail" to={generatePath(SamplesRoutes.Details, { id: sample.id })}>
          {t('sample_number', { number: sample.id })}
        </ARouterLink>
        <AntTag color="white">{t('expire_at', { date: formatDate(sample.expire_at) })}</AntTag>
      </LinkWithTagWrapper>
      {!!sample.files.length && <FileList shownFiles={1} files={sample.files} />}
    </div>
  )
}

type BidDetailSampleProps = {
  bid: Bid
}

export const BidDetailSample = ({ bid }: BidDetailSampleProps) => {
  const { t } = useTranslation('deal')

  const [addSampleModal, setAddSampleModal] = useState(false)

  const [progress, sample, sampleRefetch] = useSingleEntity<SamplesForBid[]>(endpoints.samplesForBid(bid.id))

  const isLoading = progress === Progress.WORK
  const actualSample = useMemo(() => sample?.filter((el) => el.status !== SampleStatus.archived)?.[0], [sample])

  let content

  if (isLoading) {
    return <AntSkeleton.Input active style={{ width: '100%', borderRadius: '8px', height: '75px' }} />
  }

  if (actualSample) {
    content = <SampleItem sample={actualSample} />
  } else {
    content = (
      <Wrapper>
        <div>{t('sample')}</div>
        <AButton id="add_sample" intent="approve" Icon={Icons.IconAdd} onClick={() => setAddSampleModal(true)} />
        {addSampleModal && (
          <AddSample refetch={sampleRefetch} onClose={() => setAddSampleModal(false)} bidId={bid.id} />
        )}
      </Wrapper>
    )
  }

  return (
    <div>
      <Card.Container bordered disableShadow>
        {content}
      </Card.Container>
    </div>
  )
}
