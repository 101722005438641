import { useFetchMarginsAndRevenues } from './useFetchMarginsAndRevenues'
import { CardsBoard } from 'views/components/Board/CardsBoard'
import { ListRequestParams } from 'modules/domain/user/types'
import { DealCondition } from 'modules/domain/deal/types'
import { useDealBoardColumns } from './hooks'
import { Column } from './Column'
import { FC } from 'react'

type Props = {
  listRequestParams: ListRequestParams
  typeDeals: DealCondition
  isFiltersLoaded: boolean
  activeTab: string
}

export const Board: FC<Props> = ({ listRequestParams, isFiltersLoaded, typeDeals, activeTab }) => {
  const columns = useDealBoardColumns()
  const { progress, margins, revenues } = useFetchMarginsAndRevenues(listRequestParams, isFiltersLoaded, typeDeals)

  return (
    <CardsBoard>
      {columns.map((column) => {
        return (
          <Column
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
            progressMargin={progress}
            key={column.statusDeal}
            typeDeals={typeDeals}
            activeTab={activeTab}
            revenues={revenues}
            margins={margins}
            column={column}
          />
        )
      })}
    </CardsBoard>
  )
}
