import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { FormGroup, Input } from '@agro-club/agroclub-shared'
import { useFormik } from 'formik'
import { useNotificationProgress } from 'hooks/useNotificationProgress'
import { endpoints } from 'modules/endpoints'
import { CreateSpecificationProps, SpecificationEditableFields } from 'views/components/SpecificationModals/types'
import { DatePicker } from 'views/components/DatePicker/DatePicker'
import FormSelectShared from 'views/components/FormSelectShared'
import env from 'env'
import { SharedModalContent, SharedModalInputs, SharedModalTitle } from 'views/components/SharedModal'
import { SharedModalSecondContent, SharedModalSubTitle } from 'views/components/SharedModal/styled'
import { ShortFormControl } from 'views/styled/common'
import { FormGroupUpload } from 'views/components/FormGroupUpload/FormGroupUpload'
import useFormatDate from 'hooks/useFormatDate'
import ASharedModal from '../SharedModal/ASharedModal'
import { AnalyticsPlaces } from 'views/pages/Deal/analyticsPlaces'
import ADefaultModalFooter from '../DefaultModalFooter/ADefaultModalFooter'
import { useAFormHandler } from 'analytics/hooks'
import { AddContractModal } from 'views/components/SpecificationModals/AddContractModal'
import { apiClient } from 'modules/utils/httpClient'
import { Specification } from 'modules/domain/specification/types'

export const AddSpecificationModal: FC<CreateSpecificationProps> = ({
  disableProduct,
  onClose,
  deal,
  bindingSpecToDeal,
}) => {
  const { formProgress, formHandler } = useAFormHandler('addBuyerSpecification')
  const { t } = useTranslation('specification')
  const [contractModalIsOpen, setContractModalIsOpen] = useState(false)
  const owner = deal?.purchase_bid?.owner?.id ?? ''
  const companyId = deal?.purchase_bid?.company?.id ?? ''
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const formatDate = useFormatDate()

  useNotificationProgress(formProgress, { title: t('specification_add_success') })

  const address = deal?.purchase_bid.address.id ?? ''
  const product = deal?.product.id ?? ''
  const formik = useFormik<SpecificationEditableFields>({
    initialValues: {
      owner: owner,
      company: companyId,
      number: '',
      address: String(address),
      signed_date: null,
      product: String(product),
      price: '',
      quantity: 0,
      deadline: null,
      files: [],
      contract: '',
    },

    onSubmit: formHandler(
      async () => {
        const response = await apiClient.post<Specification>(endpoints.specifications(), formik.values)
        await bindingSpecToDeal(deal.id, response.id)
      },
      {
        onSuccess: onClose,
        onError: (error) => {
          const { errors } = error
          formik.setErrors(errors)
        },
      },
    ),
  })
  return (
    <ASharedModal id={AnalyticsPlaces.ADD_SELLER_SPECIFICATION_MODAL} onClose={onClose}>
      <SharedModalTitle>{t('specification')}</SharedModalTitle>
      <SharedModalContent>
        <SharedModalInputs>
          <ShortFormControl>
            <FormGroup label={t('form.number')} error={formik.errors.number} required>
              <Input {...formik.getFieldProps('number')} invalid={!!formik.errors.number} />
            </FormGroup>
            <FormGroup label={t('form.signed_date')} error={formik.errors.signed_date} required>
              <DatePicker
                onChange={(data) => formik.setFieldValue('signed_date', data['date'])}
                error={!!formik.errors.signed_date}
                placeholder={t('common:datePlaceholder')}
              />
            </FormGroup>
          </ShortFormControl>
          <FormSelectShared
            label={t('form.address')}
            formik={formik}
            endpoint={endpoints.userAddress(owner)}
            fieldName="address"
            simpleApiFormat
            getLabel={(dto) => dto.address}
            required
          />
          <FormSelectShared
            label={t('form.product')}
            formik={formik}
            endpoint={endpoints.products()}
            fieldName="product"
            getLabel={(dto) => dto.title}
            simpleApiFormat
            disabled={disableProduct}
            required
          />
          <ShortFormControl>
            <FormGroup label={t('form.quantity')} error={formik.errors.quantity} required>
              <Input {...formik.getFieldProps('quantity')} type="number" invalid={!!formik.errors.quantity} />
            </FormGroup>
            <FormGroup label={t('form.price', { currency })} error={formik.errors.price} required>
              <Input {...formik.getFieldProps('price')} invalid={!!formik.errors.price} type="number" />
            </FormGroup>
            <FormGroup label={t('form.deadline')} error={formik.errors.deadline} required>
              <DatePicker
                onChange={(data) => formik.setFieldValue('deadline', data['date'])}
                error={!!formik.errors.deadline}
                placeholder={t('common:datePlaceholder')}
              />
            </FormGroup>
          </ShortFormControl>
          <FormGroupUpload maxWidthFileUpload={411} label={t('common:files')} fieldName="files" formik={formik} />
        </SharedModalInputs>
        <SharedModalSecondContent>
          <SharedModalSubTitle>{t('contract.contract')}</SharedModalSubTitle>
          <FormSelectShared
            label={t('form.contract_number')}
            formik={formik}
            fieldName="contract"
            endpoint={endpoints.companyContracts(companyId)}
            onSelectedOptionLoaded={({ dto }) => formik.setFieldValue('contract_signed_date', dto.signed_date)}
            onClear={() => formik.setFieldValue('contract_signed_date', '')}
            placeholder=""
            simpleApiFormat
            getLabel={(dto) => dto.title}
            required
            addButtonHandler={() => setContractModalIsOpen(true)}
          />
          <ShortFormControl>
            <FormGroup label={t('form.signed_date')} error={formik.errors.contract_signed_date}>
              <Input
                {...formik.getFieldProps('contract_signed_date')}
                disabled
                value={formatDate(formik.values.contract_signed_date)}
              />
            </FormGroup>
          </ShortFormControl>
          {contractModalIsOpen && (
            <AddContractModal
              specificationFormik={formik}
              companyId={companyId}
              onClose={() => setContractModalIsOpen(false)}
            />
          )}
        </SharedModalSecondContent>
      </SharedModalContent>
      <ADefaultModalFooter
        onClose={onClose}
        progress={formProgress}
        disabled={!formik.dirty}
        confirmButtonText={t('common:save')}
        onConfirm={() => formik.submitForm()}
      />
    </ASharedModal>
  )
}
