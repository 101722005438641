import { FC } from 'react'
import styled from 'styled-components'
import { DatePeriod, containsFilledDatePeriod } from './types'
import { DateFormatted } from '../DateFormatted'

type Wrap = {
  wrap?: boolean
}

type Props = {
  period?: DatePeriod
  className?: string
} & Wrap

const DatePeriodContainer = styled.span<Transient<Wrap>>`
  display: flex;
  gap: 0 2px;
  ${({ $wrap }) => $wrap && 'flex-wrap: wrap'}
`

const DatePeriodFormatted: FC<Props> = ({ className = '', period, wrap = false }) => {
  if (!containsFilledDatePeriod(period)) {
    return null
  }

  return (
    <DatePeriodContainer className={className} $wrap={wrap}>
      <span>{<DateFormatted date={period.start_date} />}</span>
      <span> &ndash; </span>
      <span>{<DateFormatted date={period.end_date} />}</span>
    </DatePeriodContainer>
  )
}

export default DatePeriodFormatted
