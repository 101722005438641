import { defaultTheme, Icons, Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { ARouterLink } from 'views/components/Analytics'
import { ItemCard } from 'views/styled/ItemCardStyles'

type Props = {
  margin: number
}

export const CardWrapper = styled(ItemCard)<{ isExport: boolean }>`
  width: auto;
  min-width: 298px;
  row-gap: 8px;
  background: ${({ isExport }) => (isExport ? defaultTheme.color.accentYellow800 : ``)};
  border-color: ${({ isExport }) => (isExport ? defaultTheme.color.accentYellow500 : ``)};
`

export const CompanyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StatusesWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 4px;
`

export const StatusIcons = styled.div`
  display: flex;
  column-gap: 4px;
`
export const Company = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
  ${Typography.accentLarge}
`
export const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  align-items: flex-start;
`

export const SignedMargin = styled.div<Props>`
  ${Typography.accentLarge}
  color: ${({ margin }) =>
    margin > 0 ? `${defaultTheme.color.accentApprove600}` : `${defaultTheme.color.accentNotify800}`};
`
export const MarginOnWay = styled.div`
  ${Typography.bodyMedium}
`
export const ActualMargin = styled.div`
  ${Typography.accentMedium}
`
export const FixedMargin = styled.span`
  ${Typography.accentLarge} //в шаред
  background: rgba(255, 153, 0, 0.16);
`
export const Product = styled.div`
  width: 250px;
`

export const IconTrack = styled(Icons.IconPickup)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`

export const IconUser = styled(Icons.IconAccount)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`
export const IconSample = styled(Icons.IconSampling)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`
export const IconInstructions = styled(Icons.IconOrders1)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`

export const IconSpecification = styled(Icons.IconOrders1)<{ $markSpecification: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $markSpecification }) =>
      $markSpecification ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`};
  }
`

export const IconDocuments = styled(Icons.IconGdprData)<{ $markDocumentsRequest: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $markDocumentsRequest }) =>
      $markDocumentsRequest ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`};
  }
`

export const IconContracts = styled(Icons.IconDescription)<{ $hasSaleContractRequest: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $hasSaleContractRequest }) =>
      $hasSaleContractRequest ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`};
  }
`

export const IconOneC = styled(Icons.IconOnec)<{ $approve: boolean }>`
  max-width: 16px;
  height: auto;

  path {
    fill: ${({ $approve }) => ($approve ? `${defaultTheme.color.primary600}` : `${defaultTheme.color.secondary300}`)};
  }
`

export const Link = styled(ARouterLink)`
  color: ${defaultTheme.color.textPrimary900};

  &:hover {
    text-decoration: none;
  }
`
export const NoBid = styled.div`
  border: 1px dashed ${defaultTheme.color.magenta};
  color: ${defaultTheme.color.magenta};
  border-radius: 8px;
  background: rgba(235, 47, 150, 0.08);
  padding: 12px 8px;
  display: flex;
  justify-content: center;
`
