import { FC, useEffect } from 'react'

import { AntCollapse, defaultTheme, Typography, Panel, Progress, Spinner } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { isBrazil, REACT_APP_CURRENCY_SYMBOL } from 'env'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { endpoints } from 'modules/endpoints'
import { DealTaxItem } from './types'
import { useTranslation } from 'react-i18next'
import HorizontalKeyValue from 'views/components/KeyValue/HorizontalKeyValue'
import formatPrice from 'modules/utils/numbers/formatPrice'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { BrazilProductSlug } from 'views/pages/Bid/components/Brazil/utils'
import { AgroundImputs } from 'modules/domain/bid/types'
import { DASH } from 'modules/constants'
import { useDealContext } from './DealContext'

export const Wrapper = styled.div`
  border: 1px solid ${defaultTheme.color.secondary50};
  border-radius: 8px;

  .ant-collapse {
    box-shadow: none;
  }

  .ant-collapse-content {
    border: 0;
  }

  .ant-collapse-header {
    padding: 16px !important;
  }
`

const DetailWrapper = styled.div`
  display: grid;
  grid-gap: 12px;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const DataWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
`

const ColumnTitle = styled.p`
  ${Typography.accentLarge};
  color: ${defaultTheme.color.textPrimary900};
  margin-bottom: 8px;
  margin-top: 0;
`

export const LoadingBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Direction = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
  border-bottom: 1px solid ${defaultTheme.color.secondary50};
  padding-bottom: 12px;
  margin-bottom: 12px;
`

const DealTaxCalculation: FC = () => {
  const { t } = useTranslation('deal')
  const { deal } = useDealContext()
  const [progress, data, refetch] = useSingleEntity<DealTaxItem>(endpoints.dealTaxCalculation(deal.id))
  const currency = REACT_APP_CURRENCY_SYMBOL

  useEffect(() => {
    // when deal refetched we need get new data about taxes
    refetch()
  }, [deal, refetch])

  if (progress === Progress.WORK || !data) {
    return (
      <LoadingBlock>
        <Spinner />
      </LoadingBlock>
    )
  }
  const isAgroundImputs = t(
    `common:${
      data.taxes_source_data.purchase_aground_imputs === AgroundImputs.YES ? AgroundImputs.YES : AgroundImputs.NO
    }`,
  )
  return (
    <Wrapper>
      <AntCollapse accordion>
        <Panel key={1} header={t('taxCalculation.title')}>
          <Direction>
            <HorizontalKeyValue label={t('taxCalculation.direction')} labelTypo="accentLarge">
              {t(`taxCalculation.directions.${data.taxes_source_data.direction}`)} ({data.taxes_source_data.sale_state}{' '}
              &rarr; {data.taxes_source_data.purchase_state})
            </HorizontalKeyValue>
          </Direction>
          <DataWrapper>
            <Column>
              <ColumnTitle>{t('taxCalculation.sellerSide')}</ColumnTitle>
              <DetailWrapper>
                <HorizontalKeyValue label={t('taxCalculation.quantity')}>
                  {formatNumber(data.quantity, true)}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.gross_sales', { currency })}>
                  {formatPrice(data.gross_sales)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={`${t('taxCalculation.icms_debit', { currency })} (${data.taxes_source_data.sale_icms_rate}%)`}
                >
                  {formatPrice(-data.icms_debit)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={`${t('taxCalculation.piscofins_debit', { currency })} (${
                    data.taxes_source_data.sale_piscofins_rate
                  }%)`}
                >
                  {formatPrice(-data.piscofins_debit)}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.operation')}>
                  {data.taxes_source_data.sale_operation
                    ? t(`taxCalculation.operations.${data.taxes_source_data.sale_operation}`)
                    : DASH}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.total_tax_amount', { currency })}>
                  {formatPrice(-(data.piscofins_debit + data.icms_debit))}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={t('taxCalculation.gross_sales_after_taxes', { currency })}
                  valueTypo="accentLarge"
                  labelTypo="accentLarge"
                >
                  {formatPrice(data.gross_sales_after_taxes)}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.product_net_cost', { currency })}>
                  {formatPrice(-data.product_net_cost)}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.freight_net_cost', { currency })}>
                  {formatPrice(-data.freight_net_cost)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={t('taxCalculation.gross_margin', { currency })}
                  valueTypo="accentLarge"
                  labelTypo="accentLarge"
                >
                  {formatPrice(data.gross_margin)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={t('taxCalculation.gross_margin_percent')}
                  valueTypo="accentLarge"
                  labelTypo="accentLarge"
                >
                  {formatNumber(data.gross_margin_percent, true)}
                </HorizontalKeyValue>
              </DetailWrapper>
            </Column>

            <Column>
              <ColumnTitle>{t('taxCalculation.purchaseSide')}</ColumnTitle>
              <DetailWrapper>
                <HorizontalKeyValue label={t('taxCalculation.quantity')}>
                  {formatNumber(data.quantity, true)}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.product_cost', { currency })}>
                  {formatPrice(data.product_cost)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={`${t('taxCalculation.icms_credit', { currency })} (${
                    data.taxes_source_data.purchase_icms_rate
                  }%)`}
                >
                  {formatPrice(-data.icms_credit)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={`${t('taxCalculation.piscofins_credit', { currency })} (${
                    data.taxes_source_data.purchase_piscofins_rate
                  }%)`}
                >
                  {formatPrice(-data.piscofins_credit)}
                </HorizontalKeyValue>
                <HorizontalKeyValue label={t('taxCalculation.operation')}>
                  {data.taxes_source_data.purchase_operation
                    ? t(`taxCalculation.operations.${data.taxes_source_data.purchase_operation}`)
                    : DASH}
                </HorizontalKeyValue>
                {deal.product.slug === BrazilProductSlug.MILHO && (
                  <HorizontalKeyValue label={t('bid:taxes.aground_imputs')}>{isAgroundImputs}</HorizontalKeyValue>
                )}
                <HorizontalKeyValue label={t('taxCalculation.net_product_cost', { currency })}>
                  {formatPrice(data.product_net_cost)}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.freight_cost', { currency })}>
                  {formatPrice(data.freight_cost)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={`${t('taxCalculation.freight_icms_credit', { currency })} (${
                    data.taxes_source_data.freight_icms_rate
                  }%)`}
                >
                  {formatPrice(-data.freight_icms_credit)}
                </HorizontalKeyValue>

                <HorizontalKeyValue
                  label={`${t('taxCalculation.freight_piscofins_credit', { currency })} (${
                    data.taxes_source_data.freight_piscofins_rate
                  }%)`}
                >
                  {formatPrice(-data.freight_piscofins_credit)}
                </HorizontalKeyValue>

                <HorizontalKeyValue label={t('taxCalculation.net_freight_cost', { currency })}>
                  {formatPrice(data.freight_net_cost)}
                </HorizontalKeyValue>
              </DetailWrapper>
            </Column>
          </DataWrapper>
        </Panel>
      </AntCollapse>
    </Wrapper>
  )
}

export const DealTaxCalculationWrapper: FC = () => {
  if (!isBrazil) return null

  return <DealTaxCalculation />
}
