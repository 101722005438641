import { useMemo } from 'react'
import { ColumnData } from 'modules/types'
import { useTranslation } from 'react-i18next'
import { REACT_APP_CURRENCY_SYMBOL } from 'env'
import { DriverCell } from 'views/pages/LogisticsKR/LogisticRequests/components/DriverCell'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { LogisticRequest } from 'modules/domain/logisticsKR/types'
import { CarToTripCell } from './CarToTripCell'
import { CellRenders } from 'views/components/Table/renderers'
import { LoadUploadCell } from 'views/pages/LogisticsKR/LogisticRequests/components/LoadUploadCell/LoadUploadCell'
import { DASH } from 'modules/constants'
import { useTtnFilesContext } from '../../../TtnFilesContext'

export const useRegularColumns = (
  request: LogisticRequest,
  refetchApprovingTable: () => void,
  refetchTripsTable: () => void,
): ColumnData[] => {
  const { t } = useTranslation('logisticsKR')
  const { ttnListRefetch, deletedFileId } = useTtnFilesContext()
  const currency = REACT_APP_CURRENCY_SYMBOL

  const onSuccess = () => {
    refetchTripsTable()
    ttnListRefetch(true)
  }

  const onCell = () => ({
    style: {
      verticalAlign: 'top',
    },
  })

  return useMemo(
    () => [
      {
        title: t('details.tableHeaders.load_date'),
        width: 160,
        dataIndex: 'load_date',
        onCell,
        render: (_, trip) => (
          <LoadUploadCell externallyDeletedDocId={deletedFileId} onSuccess={onSuccess} trip={trip} loadType="load" />
        ),
      },
      {
        title: t('details.tableHeaders.unload_date'),
        width: 160,
        dataIndex: 'unload_date',
        onCell,
        render: (_, trip) => (
          <LoadUploadCell externallyDeletedDocId={deletedFileId} onSuccess={onSuccess} trip={trip} loadType="unload" />
        ),
      },
      {
        title: t('details.tableHeaders.ttn'),
        width: 80,
        onCell,
        dataIndex: 'ttn_number',
      },
      {
        title: t('details.tableHeaders.driver'),
        dataIndex: 'driver',
        onCell,
        render: (driver, data) => <DriverCell driver={driver} logistics_request={data} />,
      },
      {
        title: t('details.tableHeaders.logistician'),
        width: 100,
        onCell,
        dataIndex: 'logistician',
        render: (logistician) => (logistician ? logistician.short_name || logistician.full_name : DASH),
      },
      {
        title: t('details.tableHeaders.transit'),
        width: 76,
        dataIndex: 'transit',
        onCell,
        render: CellRenders.YesNo,
      },
      {
        title: t('details.tableHeaders.price_logistics', { currency }),
        width: 100,
        dataIndex: 'price_logistics',
        align: 'right',
        onCell,
        render: (price_logistics) => formatNumber(price_logistics, true),
      },
      {
        title: '',
        width: 48,
        dataIndex: 'car',
        onCell,
        render: (car, trip) => (
          <CarToTripCell trip={trip} request={request} refetchTripsReconciliation={refetchApprovingTable} />
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [deletedFileId, t],
  )
}
