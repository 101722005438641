import { Text, OptionType, Icons } from '@agro-club/agroclub-shared'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bid, USBid } from 'modules/domain/bid/types'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import styled from 'styled-components'
import { FormikProps } from 'formik'
import { endpoints } from 'modules/endpoints'
import FormSelectShared from 'views/components/FormSelectShared'
import { CreateDealFields } from './types'
import { BidCreateModal } from 'views/pages/Bid/BidModals/BidCreateModal'
import { User } from 'modules/domain/user/types'
import { AButton } from 'views/components/Analytics'
import { USBidEditModal } from 'views/pages/Bid/BidModals/Bid/USModals/USBidEditModal'
import { AddressExtraInfo } from 'views/components/AddressTitle/AddressTitle'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import { Product } from 'modules/domain/types'

const StyledOption = styled.div`
  white-space: normal;
`

const Option: FC<{ option: OptionType }> = ({ option }) => {
  const bid = option.dto as USBid
  const date =
    bid.start_date || bid.end_date ? (
      <DatePeriodFormatted period={{ start_date: bid.start_date, end_date: bid.end_date }} />
    ) : null
  return (
    <StyledOption>
      <Text typography="bodyLarge">{option.label}</Text>
      <AddressExtraInfo address={bid.address} />
      <Text typography="bodyMedium" color="secondary">
        {bid.address.address}
        {date}
      </Text>
    </StyledOption>
  )
}

interface Props {
  formik: FormikProps<CreateDealFields>
  onChange?: (bid: USBid) => void
  product: Product
  user?: User
  symbol?: string
}

export const BidControl: FC<Props> = ({ onChange, formik, product, user, symbol }) => {
  const { t } = useTranslation('deal')
  const [isCreateOpen, setIsCreateOpen] = useState(false)
  const [isEditOpen, setIsEditOpen] = useState(false)
  const [bid, setBid] = useState<USBid>()
  const [bidFilter, setFilter] = useState({ product: product.slug })
  // key is used to reinitialize control to force it to load new data after edit
  const [key, setKey] = useState(Date.now())

  useEffect(() => {
    formik.setFieldValue('counter_bid_id', null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, formik.values.market_zone])

  useEffect(() => {
    setFilter((prev) => ({ ...prev, market_zone: formik.values.market_zone ?? undefined }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.market_zone])

  const getSelectedOptLabel = (bid: Bid): string => {
    const result = [
      bid.product.title,
      formatQuantity(bid.quantity),
      bid.parameters.length ? bid.parameters[0].description : '',
      formatTarif(bid.price),
    ]
      .filter(Boolean)
      .join(', ')

    return result
  }

  const onBidCreateSuccess = (newBid) => {
    formik.setFieldValue('counter_bid_id', newBid.id)
    setIsCreateOpen(false)
  }

  return (
    <>
      <FormSelectShared
        key={key}
        placeholder={t('bidCloningModal.bid_placeholder')}
        endpoint={formik.values.user ? endpoints.userOpenBids(formik.values.user.toString()) : undefined}
        label={t('bidCloningModal.bid')}
        OptionComponent={Option}
        filter={bidFilter}
        getLabel={(dto) => getSelectedOptLabel(dto)}
        searchField="search"
        fieldName="counter_bid_id"
        formik={formik}
        onChange={onChange}
        onSelectedOptionLoaded={(o) => setBid(o.dto)}
        simpleApiFormat
        disabled={!formik.values.user}
      >
        <AButton
          id="editBid"
          intent="secondary"
          size="big"
          disabled={!formik.values.user || !bid || !formik.values.counter_bid_id}
          Icon={Icons.IconEdit}
          onClick={() => setIsEditOpen(true)}
        />
        <AButton
          id="addBid"
          intent="approve"
          size="big"
          disabled={!formik.values.user}
          Icon={Icons.IconPlus}
          onClick={() => setIsCreateOpen(true)}
        />
      </FormSelectShared>
      {isEditOpen && bid && (
        <USBidEditModal
          onClose={() => setIsEditOpen(false)}
          refetch={async () => {
            //remap control
            setKey(Date.now())
          }}
          bid={bid}
        />
      )}
      {isCreateOpen && (
        <BidCreateModal
          user={user}
          productId={String(product.id)}
          symbol={symbol}
          onClose={() => setIsCreateOpen(false)}
          onSuccess={onBidCreateSuccess}
        />
      )}
    </>
  )
}
