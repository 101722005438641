import { DetailPageTitle, DetailPageTitleWithTag } from './styled'
import { AntTag } from '@agro-club/agroclub-shared'
import { FuturesPeriodTag } from 'views/components/Tags'
import { FC } from 'react'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { DealStatusLabel } from './DealStatusLabel'
import { isDealClosed } from 'modules/domain/deal/utils'

interface Props {
  deal: Deal
}

export const DealHeaderTags: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('deal')

  return (
    <DetailPageTitleWithTag>
      <DetailPageTitle>
        {deal?.product.title || ''}
        {deal.season && `, ${deal.season.name}`}
      </DetailPageTitle>
      {isDealClosed(deal) && (
        <AntTag color="grey">{`${t('isClosed')} / ${t(`closed_reasons.${deal.closed_reason}`)}`}</AntTag>
      )}
      <DealStatusLabel status={deal.status} />
      <FuturesPeriodTag value={deal} />
    </DetailPageTitleWithTag>
  )
}
