import { PreloadCard, PreloadCount } from 'views/components/Board/Preload'
import { AntTagColor, Panel, Progress } from '@agro-club/agroclub-shared'
import { Count, WrapperCards } from 'views/components/Board/styled'
import { NoDataCard } from 'views/components/Board/NoDataCard'
import { Bid, BidStatus } from 'modules/domain/bid/types'
import { BidCard } from './BidCard'
import { FC } from 'react'

type Props = {
  setActiveBid?: (arg: Bid) => void
  colorCount: AntTagColor
  progress: Progress
  namePanel: string
  activeBid?: Bid
  key: BidStatus
  bids: Bid[]
}

export const BidsPanel: FC<Props> = ({ namePanel, bids, colorCount, setActiveBid, activeBid, progress, ...props }) => {
  const preloadWrapper = progress === Progress.WORK && <PreloadCard />
  const preloadCount = progress === Progress.WORK && <PreloadCount />

  return (
    <Panel
      {...props}
      header={
        <>
          {namePanel}
          {preloadCount || <Count color={colorCount}>{bids.length || 0}</Count>}
        </>
      }
    >
      <WrapperCards>
        {bids.map((bid) => (
          <BidCard
            setActiveBid={() => setActiveBid?.(bid)}
            activeCard={bid.id === activeBid?.id}
            key={bid.id}
            bid={bid}
          />
        ))}

        {preloadWrapper}
        {!bids.length && !preloadWrapper && <NoDataCard />}
      </WrapperCards>
    </Panel>
  )
}
