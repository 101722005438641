import { FC, useState } from 'react'
import { BidTypes } from 'modules/domain/bid/types'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { getDealBidAndContract } from 'modules/domain/deal/utils'
import { useAddress } from '../../useAddress'
import { isPurchaseBid } from 'modules/domain/bid/utils'
import { BuyerWarehouseEditModal } from 'views/components/WarehouseModal/BuyerWarehouseEditModal'
import { SellerWarehouseEditModal } from 'views/components/WarehouseModal/SellerWarehouseEditModal'
import { Card } from 'views/components/Card/Card'

type Props = {
  deal: Deal
  bidType: BidTypes
  disabled?: boolean
  refetch: refetchFunc
}

export const WarehouseEditAction: FC<Props> = ({ disabled = false, refetch, deal, bidType }) => {
  const { bid, contract } = getDealBidAndContract(deal, bidType)
  const contractOrBid = contract ?? bid ?? undefined
  const isPurchase = isPurchaseBid(contractOrBid)
  const DealWarehouseEditModal = isPurchase ? BuyerWarehouseEditModal : SellerWarehouseEditModal

  const [modalVisible, setModalVisible] = useState(false)
  const [, , dealAddressRefetch] = useAddress(contractOrBid?.owner.id, contractOrBid?.address.id)

  const onAddressUpdate = async () => {
    await refetch()
    await dealAddressRefetch()
  }

  if (!contractOrBid || disabled) {
    return null
  }

  return (
    <>
      <Card.EditButton
        id={isPurchase ? 'editBuyerWarehouse' : 'editSellerWarehouse'}
        onClick={() => {
          setModalVisible(true)
        }}
      />
      {modalVisible && (
        <DealWarehouseEditModal
          onClose={() => setModalVisible(false)}
          bid={contractOrBid}
          onSuccess={onAddressUpdate}
          dealId={deal.id}
        />
      )}
    </>
  )
}
