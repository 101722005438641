import { UserCompanyLink } from '../UserDetail/UserCompanyLink/UserCompanyLink'
import { defaultListRequestParams } from 'views/components/TableFilters/data'
import { useSingleEntity, useTableData } from 'modules/domain/common/hooks'
import { generatePath, useParams } from 'react-router-dom'
import { ListRequestParams } from 'modules/domain/types'
import { useHelmet } from '@agro-club/agroclub-shared'
import * as Layout from 'views/layouts/NewLayout'
import { User } from 'modules/domain/user/types'
import UserRoutes from 'views/pages/User/routes'
import * as Header from 'views/ui/Header/Header'
import { useTranslation } from 'react-i18next'
import { endpoints } from 'modules/endpoints'
import { useFilters } from 'hooks/useFilter'
import { FC, useState } from 'react'

import styled from 'styled-components'
import { Filters } from './Filters'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import APhone from 'views/ui/Phone/APhone'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { BidPricesTable } from './BidPricesTable'
import { Bid } from 'modules/domain/bid/types'

const TableWrapper = styled.div`
  .ant-table-body {
    .ant-table-cell {
      padding: 4px 12px;
    }
    .bold-row {
      td {
        font-weight: 600;
      }
    }
  }
`

export const UserBidPrice: FC = () => {
  const { t } = useTranslation()
  const { id, topBid } = useParams()
  const [, user] = useSingleEntity<User>(endpoints.getUserById(id))
  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>(defaultListRequestParams)

  useHelmet({ title: `${t('user:priceUpdating')} - ${user?.profile?.full_name}` })
  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
  })
  const tableData = useTableData<Bid>(
    endpoints.userBidListWithPagination(id, topBid),
    listRequestParams,
    isFiltersLoaded,
  )

  const params = { listRequestParams, listParamsUpdated: setListRequestParams }
  const inn = user?.profile?.company?.inn

  return (
    <APageWrapper page={AnalyticsPages.USER_BID_PRICE} place={AnalyticsPlaces.BID_PRICE} id={id}>
      <Layout.WrapperContent>
        <Layout.Header>
          <Header.Breadcrumbs
            routes={[
              { path: UserRoutes.List, breadcrumbName: t('user:list.heading') },
              {
                path: generatePath(UserRoutes.Details, { id: id }),
                breadcrumbName: user?.profile?.full_name,
                key: { id },
              },
              { breadcrumbName: t('user:priceUpdating') },
            ]}
          />
          <Header.Title compact title={t('user:priceUpdating')} />
          <Header.SubTitle>
            {user?.profile?.company && (
              <UserCompanyLink
                companyId={user?.profile?.company.id || ''}
                companyName={user?.profile?.company.name || ''}
              />
            )}
            {inn && `/ ${getPersonalNumberLabel()}: ${inn} / `}
            <APhone phone={user?.phone || ''} />
          </Header.SubTitle>

          <Filters
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
            userId={id}
          />
        </Layout.Header>
        <TableWrapper>
          <BidPricesTable topBid={topBid} tableData={tableData} paramsData={params} user={user} />
        </TableWrapper>
      </Layout.WrapperContent>
    </APageWrapper>
  )
}
