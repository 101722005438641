import { apiClient } from 'modules/utils/httpClient'
import { endpoints } from 'modules/endpoints'
import { RoutesFileResponse } from 'modules/domain/routes/types'
import { useAProgress } from 'hooks/useAProgress'
import { AUploadFileButton } from 'views/components/UploadFileButton/AUploadFileButton'
import { Icons } from '@agro-club/agroclub-shared'

/*
   can't use a generic uploadFile because the response is not typical for a generic
 * */
const uploadFile = (file: File) => {
  const form = new FormData()
  form.append('uploaded_file', file)
  return apiClient.post<RoutesFileResponse>(endpoints.brRoutesUploadFile(), form)
}

type AddFileButtonProps = {
  setFileResponse: (file: RoutesFileResponse) => void
}

export const AddFileButton = ({ setFileResponse }: AddFileButtonProps) => {
  const [progress, addHandler] = useAProgress(async (file: File) => await uploadFile(file), {
    eventName: 'addRoutesFile',
    onSuccess: (response) => setFileResponse(response),
  })

  return (
    <AUploadFileButton
      eventName="addRoutesFile"
      icon={Icons.IconUpload}
      multiple
      progress={progress}
      accept=".xlsx"
      onUpload={addHandler}
    />
  )
}
