import { DocumentRequestControl } from './DocumentRequestControl/DocumentRequestControl'
import { DealExecutorsBlock } from './DealExecutorsBlock/DealExecutorsBlock'
import { DealDetailBlock } from './DealDetailBlock/DealDetailBlock'
import { DealTaxCalculationWrapper } from './DealTaxCalculation'
import { Declaration } from 'modules/domain/types'
import { Card } from 'views/components/Card/Card'
import { Shipments } from './Shipments/Shipments'
import styled from 'styled-components'
import { isBrazil } from 'env'
import { Bids } from './Bids/Bids'
import { FC } from 'react'

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`

interface DealEditProps {
  declaration: Declaration | undefined
}

const ShipmentsBlock: FC = () => {
  if (!isBrazil) return null
  return <Shipments />
}

export const DealDetailContent: FC<DealEditProps> = ({ declaration }) => {
  return (
    <Card.Container>
      <ContentWrapper>
        <DealExecutorsBlock />
        <DealDetailBlock />
        <DealTaxCalculationWrapper />
        <ShipmentsBlock />
        <DocumentRequestControl declaration={declaration} />
        <Bids />
      </ContentWrapper>
    </Card.Container>
  )
}
