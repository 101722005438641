import { DispatchingStatusesTags } from 'views/components/DispatchingStatusesTags/DispatchingStatusesTags'
import { TruncatedValueWrapper } from 'views/components/TruncatedValue/TruncatedValue'
import { CompanyFactoringIcon } from 'views/components/TooltipIcons/TooltipIcons'
import { AntTag, Icons, Text, defaultTheme } from '@agro-club/agroclub-shared'
import { formatQuantity } from 'modules/utils/numbers/formatQuantity'
import { DispatchingStatuses, DispatchingListType } from '../../types'
import { formatTarif } from 'modules/utils/numbers/formatTarif'
import { EntityWithIconsWrapper } from 'views/styled/common'
import formatPrice from 'modules/utils/numbers/formatPrice'
import { AButton } from 'views/components/Analytics'
import { useTranslation } from 'react-i18next'
import { EditDispatch } from './EditDispatch'
import { DASH } from 'modules/constants'
import styled from 'styled-components'
import { FC, useState } from 'react'
import { Detail } from './Detail'

const Card = styled.div`
  background: ${defaultTheme.color.backgroundSecondary};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  position: relative;
  &:first-child {
    border-radius: 16px 16px 0 0;
  }
  &:last-child {
    border-radius: 0 0 16px 16px;
  }
`
const Tags = styled.div`
  display: flex;
  gap: 0 8px;
`
const CommentBlock = styled.div`
  padding: 8px;
  background: ${defaultTheme.color.backgroundPrimary};
  border-radius: 8px;
`
const EditBtn = styled(AButton)`
  position: absolute;
  right: 10px;
  top: 10px;
`

const CompanyInfo = ({ company, addressTitle }) => (
  <div>
    <EntityWithIconsWrapper>
      <Text typography="accentLarge">
        <TruncatedValueWrapper maxWidth={250}>{company.name}</TruncatedValueWrapper>
      </Text>
      <CompanyFactoringIcon factoringStatus={company.factoring_status} />
    </EntityWithIconsWrapper>
    <Text typography="bodySmall" color="secondary">
      {addressTitle}
    </Text>
  </div>
)

type Props = {
  data: DispatchingListType
  datesArray: string[]
  refetch: () => void
}

export const CardDispatch: FC<Props> = ({ data, datesArray, refetch }) => {
  const { t } = useTranslation('dispatching')

  const [isOpenDetail, setIsOpenDetail] = useState(false)
  const [isOpenEdit, setIsOpenEdit] = useState(false)

  const openEditModal = (e) => {
    e.stopPropagation()
    setIsOpenEdit(true)
  }

  const isClosed = data.status == DispatchingStatuses.closed

  return (
    <>
      <Card onClick={() => setIsOpenDetail(true)}>
        {!isClosed && (
          <EditBtn size="big" id="edit" intent="white" Icon={Icons.IconEdit} onClick={(e) => openEditModal(e)} />
        )}
        <Tags>
          <AntTag color="white">{data.product_title}</AntTag>
          <DispatchingStatusesTags status={data.status} />
        </Tags>
        <CompanyInfo company={data.seller} addressTitle={data.seller_address_title} />
        <CompanyInfo company={data.customer} addressTitle={data.customer_address_title} />
        <div>
          <Text as="span" typography="accentLarge">
            {formatQuantity(data.quantity)}{' '}
          </Text>
          <Text as="span" typography="bodyLarge">
            {`(${t('table.planned_remaining_quantity_without_ton')} ${formatQuantity(
              data.planned_remaining_quantity,
            )})`}
          </Text>
        </div>
        <div>
          <Text as="span" typography="accentLarge">
            {formatTarif(data.price_exw)}{' '}
          </Text>
          <Text as="span" typography="bodyLarge">
            {`(${formatPrice(data.cost, true)})`}
          </Text>
        </div>
        <Text>{`${t('table.remaining')} ${formatPrice(data.remaining_cost, true)}`}</Text>
        {!isClosed && <Text>{`${t('cars_expected')} ${data.trips_expected || DASH}`}</Text>}
        <div>
          {data.logisticians.map((item, index) => {
            return <Text key={index}>{item.full_name}</Text>
          })}
        </div>

        {!!data.comments.length && (
          <CommentBlock>
            <Text typography="bodySmall">
              <TruncatedValueWrapper>{data.comments[0].comment}</TruncatedValueWrapper>
            </Text>
          </CommentBlock>
        )}
      </Card>
      {isOpenDetail && (
        <Detail onClose={() => setIsOpenDetail(false)} datesArray={datesArray} refetch={refetch} data={data} />
      )}

      {isOpenEdit && <EditDispatch onClose={() => setIsOpenEdit(false)} data={data} refetch={refetch} />}
    </>
  )
}
