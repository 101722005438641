import { FC, useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { apiClient } from 'modules/utils/httpClient'
import { CompanyDadata } from 'modules/domain/collection/types'
import { endpoints } from 'modules/endpoints'
import { FormGroup, Typography, OptionType, Progress, Select, SelectProps } from '@agro-club/agroclub-shared'
import styled from 'styled-components'
import { CompanyShortInfo } from 'views/pages/Company/CompanyDetail/CompanyShortInfo'

export const Name = styled.div`
  ${Typography.bodyLarge}
`
export const InnAddress = styled.div`
  color: ${(props) => props.theme.color.textSecondary60a};
  ${Typography.buttonLarge}
`
export const StyledOption = styled.div`
  white-space: normal;
`

const Option: FC<{ option: OptionType }> = ({ option }) => {
  return (
    <StyledOption>
      <Name>{option.dto.name_short}</Name>
      <InnAddress>{`${option.dto.inn} ${option.dto.address_short}`}</InnAddress>
    </StyledOption>
  )
}

interface Props extends Partial<SelectProps> {
  required?: boolean
  error?: any
  onChange?: (CompanyDadata: CompanyDadata | undefined) => void
  initialValue?: OptionType
  label?: string
  turnOffInfo?: boolean
}

export const FormSelectDadata: FC<Props> = ({
  label,
  required,
  onChange,
  error,
  initialValue,
  children,
  turnOffInfo,
  ...otherProps
}) => {
  const [searchVal, setSearchVal] = useState('')
  const [options, setOptions] = useState<OptionType[]>([])
  const [progress, setProgress] = useState<Progress>(Progress.IDLE)
  const [value, setValue] = useState<OptionType | undefined>(initialValue)
  const { t } = useTranslation('company')

  const onSearch = (searchValue = '') => {
    if (searchVal !== searchValue) {
      setSearchVal(searchValue)
    }
  }

  const handleChange = (data) => {
    const opt = options.find((c) => c.dto.inn === data?.value)
    setValue(opt)
    onChange?.(opt?.dto)
  }

  useEffect(() => {
    if (!searchVal) return
    setProgress(Progress.WORK)
    apiClient
      .get<CompanyDadata[]>(endpoints.companyDadata(), { query: searchVal })
      .then((companies) => {
        setOptions(
          companies.map((c) => ({
            value: c.inn,
            label: c.name_short ?? c.name_full,
            dto: c,
          })),
        )
        setProgress(Progress.SUCCESS)
      })
      .catch(() => setProgress(Progress.ERROR))
  }, [searchVal])

  const debouncedOnSearch = _.debounce(onSearch, 500)
  const margin = 222
  return (
    <div>
      <FormGroup label={label || t('common:company')} required={required} error={error}>
        <Select
          OptionComponent={Option}
          placeholder={t('form.selectPlaceholder')}
          onChange={handleChange}
          value={value}
          labelInValue
          options={options}
          onSearch={debouncedOnSearch}
          status={progress === Progress.ERROR || error ? 'error' : ''}
          filterOption={false}
          fullWidth
          loading={progress === Progress.WORK}
          {...otherProps}
        />
      </FormGroup>
      {children}
      {!turnOffInfo && (
        <div style={{ marginLeft: `${margin}px` }}>
          <CompanyShortInfo company={value?.dto} />
        </div>
      )}
    </div>
  )
}
