import { FC, useState } from 'react'
import { FormikProps } from 'formik'
import { FormSelectDadata } from 'views/components/FormSelectDadata/FormSelectDadata'
import { HintText } from '../../LogisticRequests/components/styled'
import { Checkbox, FormGroup, Input } from '@agro-club/agroclub-shared'
import { AddCarrierModal } from '../../CarriersKR/AddCarrierModal/AddCarrierModal'
import { ShortFormControl } from 'views/styled/common'
import { MarginWrapper } from './styled'
import { useTranslation } from 'react-i18next'
import env from '../../../../../env'
import { DraftTripsInitial } from '../../LogisticRequests/RequestsBoard/OpenRequestsBoardColumns/DraftTrips/types'
import { useAnalyticsClickHandler } from 'analytics/hooks'
import { HrWithHeader } from 'views/components/Hr/Hr'

interface Props {
  formik: FormikProps<DraftTripsInitial>
  controlsDisable: Record<string, boolean> | undefined
  isExistingAgentInDB: boolean
  setIsExistingAgentInDB: (arg: boolean) => void
}

export const AgentControls: FC<Props> = ({ formik, controlsDisable, isExistingAgentInDB, setIsExistingAgentInDB }) => {
  const { t } = useTranslation('logisticBids')
  const [addAgentModal, setAddAgentModal] = useState(false)
  const currency = env.REACT_APP_CURRENCY_SYMBOL
  const handleClick = useAnalyticsClickHandler('add')

  const handleChangeAgent = (data) => {
    setIsExistingAgentInDB(true)
    if (formik.values?.agent_id) {
      formik.setFieldValue('agent_id', '')
      formik.setFieldValue('agent_name', '')
    }
    formik.setFieldValue('agent_inn', data?.inn)
    formik.setFieldValue('agent_name', data?.name_short ?? data?.name_full)
  }

  const agentValue = () => {
    if (formik.values.agent_inn) {
      return {
        value: formik.values?.agent_inn,
        label: formik.values?.agent_name,
      }
    }

    if (formik.values.agent_id) {
      return {
        value: formik.values?.agent_id,
        label: formik.values?.agent_name,
      }
    }

    return undefined
  }

  return (
    <>
      <HrWithHeader text={t('form.block_titles.mediator_info')} />
      <FormSelectDadata
        error={formik.errors.agent_id || formik.errors.agent_inn || formik.errors.agent_name}
        turnOffInfo
        label={t('form.labels.mediator')}
        value={agentValue()}
        onChange={handleChangeAgent}
        disabled={controlsDisable?.agent}
        addButtonHandler={!controlsDisable?.agent ? handleClick(() => setAddAgentModal(true)) : undefined}
      >
        {!formik.values?.agent_id && !isExistingAgentInDB && <HintText>{t('form.hints.no_agent_in_db')}</HintText>}
        <MarginWrapper>
          <Checkbox
            isChecked={formik.values.has_agent_vat}
            onChange={(_value, isChecked) => formik.setFieldValue('has_agent_vat', isChecked)}
            label={t('form.labels.vat')}
          />
        </MarginWrapper>
        {addAgentModal && (
          <AddCarrierModal
            title={t('form.titles.add_agent')}
            daData={{
              inn: formik.values?.agent_inn,
              name_full: formik.values?.agent_name,
            }}
            onClose={() => setAddAgentModal(false)}
            setValue={(val) => {
              formik.setFieldValue('agent_id', val?.id)
              formik.setFieldValue('agent_inn', val?.inn)
              formik.setFieldValue('agent_phone', val?.phone)
              formik.setFieldValue('agent_name', val?.name)
            }}
          />
        )}
      </FormSelectDadata>

      <ShortFormControl>
        <FormGroup label={t('form.labels.agent_commission', { currency })}>
          <Input {...formik.getFieldProps('agent_commission')} />
        </FormGroup>
      </ShortFormControl>
    </>
  )
}
