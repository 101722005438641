import { RemainingCountAndTooltip } from 'views/pages/Deal/DealDetail/DealExecutorsBlock/RemainingCountAndTooltip'
import HorizontalKeyValue from 'views/components/KeyValue/HorizontalKeyValue'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { useMediaQuery } from 'react-responsive'
import { Deal } from 'modules/domain/deal/types'
import { useTranslation } from 'react-i18next'
import { getName } from './helpers'
import { FC } from 'react'

interface Props {
  deal: Deal
}

export const Logisticians: FC<Props> = ({ deal }) => {
  const { t } = useTranslation('executors')

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const Component = isMobile ? KeyValue : HorizontalKeyValue

  return (
    <Component label={t(`logisticians`)} columnGap="medium" labelTypo="bodyMedium" valueTypo="bodyLarge">
      {!!deal.executors?.logisticians.length ? (
        <div>
          {getName(deal.executors?.logisticians[0])}
          &nbsp;
          <RemainingCountAndTooltip executors={deal.executors?.logisticians} />
        </div>
      ) : (
        t('not_selected')
      )}
    </Component>
  )
}
