import { FC, useState } from 'react'
import { SharedModalInputs } from 'views/components/SharedModal/styled'
import { FormGroup, YandexAddressFormV2 } from '@agro-club/agroclub-shared'
import { REGULAR_LABEL_GAP, REGULAR_LABEL_WIDTH } from 'views/pages/LogisticsKR/constants'
import { useTranslation } from 'react-i18next'
import { REACT_APP_YA_MAPS_API_KEY, REACT_APP_YA_MAPS_SUGGEST_API_KEY } from 'env'
import { CommonUnloadWHForm } from 'views/pages/LogisticsKR/components/CommonUnloadWHForm'
import { FormikFieldsType } from 'modules/domain/types'

interface Props {
  formik: FormikFieldsType
}

export const AddUnloadingModalContent: FC<Props> = ({ formik }) => {
  const { t } = useTranslation('logisticsKR')
  const [address, setAddress] = useState<string | undefined>(formik.values.unloading_warehouse_address)

  const handleNewAddress = (value) => {
    if (!value) return

    const { address, coords, geoObject } = value
    const [latitude, longitude] = coords

    formik.setFieldValue('unloading_warehouse_address', address)
    formik.setFieldValue('unloading_warehouse_latitude', latitude)
    formik.setFieldValue('unloading_warehouse_longitude', longitude)
    formik.setFieldValue('unloading_warehouse_geo_object', geoObject)
  }

  return (
    <SharedModalInputs>
      <FormGroup
        error={formik.errors.unloading_warehouse_address}
        label={t('list.createForm.labels.address')}
        labelMinWidth={REGULAR_LABEL_WIDTH}
        labelGap={REGULAR_LABEL_GAP}
        containerStyle={{ alignItems: 'stretch' }}
        markerContainerStyle={{ top: '8%' }}
        required
      >
        <YandexAddressFormV2
          apikey={REACT_APP_YA_MAPS_API_KEY}
          suggest_apikey={REACT_APP_YA_MAPS_SUGGEST_API_KEY}
          onChange={handleNewAddress}
          placeholder={t('list.createForm.placeholders.systemAddress')}
          initialCoords={[formik.values.unloading_warehouse_latitude, formik.values.unloading_warehouse_longitude]}
          value={address}
          setValue={setAddress}
        />
      </FormGroup>

      <CommonUnloadWHForm formik={formik} />
    </SharedModalInputs>
  )
}
