import { FactoringStatus } from 'modules/domain/company/types'
import { StatusItemWrapper } from './styled'
import { Icons, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { isFactoringAvailable } from 'env'

interface Props {
  factoringStatus: FactoringStatus | undefined
}

export const FactoringStatuses = ({ factoringStatus }: Props) => {
  const { t } = useTranslation('company')

  if (!factoringStatus || !isFactoringAvailable) return null

  const factoringStatusData = [
    {
      status: FactoringStatus.in_progress,
      color: defaultTheme.color.secondary300,
      label: t('factoringStatuses.in_progress'),
    },
    {
      status: FactoringStatus.accepted,
      color: defaultTheme.color.primary600,
      label: t('factoringStatuses.accepted'),
    },
    {
      status: FactoringStatus.cancelled,
      color: defaultTheme.color.accentDestruct600,
      label: t('factoringStatuses.canceled'),
    },
  ]
  const currentItem = factoringStatusData.find((item) => item.status === factoringStatus)

  return (
    <StatusItemWrapper iconColor={currentItem?.color}>
      <Icons.IconFactoring />
      {currentItem?.label}
    </StatusItemWrapper>
  )
}
