import { FC, ReactNode, useMemo } from 'react'

import { Progress, Spinner } from '@agro-club/agroclub-shared'

import { DocumentFile, DocumentType } from 'modules/domain/types'
import FileItem from 'views/components/FilesByTypes/FileItem'
import FilesGroup from 'views/components/FilesByTypes/FilesGroup'
import { FilesLayout, Loading } from 'views/components/FilesByTypes/styled'

export interface InternalFilesByTypesProps {
  types: DocumentType[]
  files: DocumentFile[]
  description?: Record<string, ReactNode>
  isAdd?: boolean
  loading?: boolean
  uploadProgress?: Progress
  deleteProgress?: Progress
  onUpload?: (document_type: string, file: File) => void
  onDelete?: (document_id: string) => void
}

const InternalFilesByTypes: FC<InternalFilesByTypesProps> = ({
  types = [],
  files = [],
  onUpload,
  onDelete,
  loading,
  isAdd,
  uploadProgress,
  deleteProgress,
  description,
}) => {
  const tree = useMemo(
    () =>
      types.map((type) => ({
        ...type,
        files: files.filter((file) => file.document_type === type.slug),
      })),
    [types, files],
  )

  const onUploadHandler = (document_type, file) => {
    if (file && onUpload) {
      onUpload(document_type, file)
    }
  }

  return (
    <FilesLayout>
      {loading && (
        <Loading>
          <Spinner />
        </Loading>
      )}

      {tree.map((item) => (
        <FilesGroup
          isAdd={isAdd}
          key={item.slug}
          header={item.label}
          onUpload={(file) => onUploadHandler(item.slug, file)}
          progress={uploadProgress}
          description={description?.[item.slug] ?? ''}
        >
          {item.files.length > 0 &&
            item.files.map((file) => (
              <FileItem key={file.id} file={file} onDelete={onDelete} progress={deleteProgress} />
            ))}
        </FilesGroup>
      ))}
    </FilesLayout>
  )
}

export default InternalFilesByTypes
