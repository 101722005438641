import FormSelectShared, { FormSelectSharedProps } from '../../FormSelectShared'
import { WrapperFormControl } from '../styled'
import { useTranslation } from 'react-i18next'
import { FC, useEffect, useState } from 'react'
import { isUS } from 'env'
import { AddressExtraInfo, MarketZone } from 'views/components/AddressTitle/AddressTitle'
import { Address } from 'modules/domain/types'
import { OptionType } from '@agro-club/agroclub-shared'
import { FormMarketZone } from '../FormMarketZone/FormMarketZone'

const Option: FC<{ option: OptionType }> = ({ option }) => {
  const address = option.dto as Address
  return (
    <>
      <AddressExtraInfo address={address} />
      {address.address}
    </>
  )
}

const getLabel = (dto) => {
  const addr = dto as Address
  if (isUS) {
    return (
      <div title={addr.address}>
        {addr.market_zone && (
          <>
            <MarketZone as="span" accent>
              {addr.market_zone?.name}
            </MarketZone>
            {', '}
          </>
        )}
        {addr.address}
      </div>
    )
  }
  return dto.address
}

export const AddressCommon: FC<FormSelectSharedProps> = ({ formik, fieldName, ...props }) => {
  const { t } = useTranslation()
  const [filter, setFilter] = useState<Record<string, unknown>>()

  useEffect(() => {
    if (!isUS) return
    formik.setFieldValue(fieldName, null)
    setFilter({ market_zone: formik.values.market_zone ?? undefined })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldName, formik.values.market_zone])

  return (
    <>
      <FormMarketZone formik={formik as any} />
      <WrapperFormControl>
        <FormSelectShared
          formik={formik}
          fieldName={fieldName}
          placeholder={t('bid:form.userAddressPlaceholder')}
          getLabel={getLabel}
          showSearch={false}
          simpleApiFormat
          OptionComponent={Option}
          filter={filter}
          {...props}
        />
      </WrapperFormControl>
    </>
  )
}
