import { Deal } from 'modules/domain/deal/types'
import { endpoints } from 'modules/endpoints'
import { RespFile } from 'modules/domain/types'
import { apiClient } from 'modules/utils/httpClient'
import { uploadFile } from 'modules/domain/common/managers'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { FC, useEffect } from 'react'
import { useAProgress } from 'hooks/useAProgress'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { Progress } from '@agro-club/agroclub-shared'

type Props = {
  deal: Deal
  setSummary: (arg: number) => void
}

export const DealSellerFiles: FC<Props> = ({ deal, setSummary }) => {
  const ownerId = deal?.sale_bid?.owner.id
  const [sellerFilesProgress, sellerFiles = [], sellerFilesRefetch] = useSingleEntity<RespFile[]>(
    endpoints.userFiles(ownerId),
  )

  useEffect(() => {
    if (sellerFilesProgress === Progress.SUCCESS) {
      setSummary(sellerFiles.length)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sellerFiles.length])

  const [, removeHandler] = useAProgress((ownerId, fileId) => apiClient.delete(endpoints.userFiles(ownerId, fileId)), {
    eventName: 'removeFile',
    analyticsPayload: {
      id: deal.id,
    },
    onSuccess: sellerFilesRefetch,
  })

  const addHandler = async (ownerId: string, file: File) => {
    await uploadFile(endpoints.userFiles(ownerId), file)
    sellerFilesRefetch(true)
  }

  return (
    <AUploadFiles
      id="sellerFile"
      progress={sellerFilesProgress}
      removeFile={(fileId) => removeHandler(ownerId, fileId)}
      addFile={({ file }) => addHandler(ownerId, file)}
      fileList={sellerFiles}
      readonly
    />
  )
}
