import { BidDetailBlock } from '../BidDetailBlock'
import styled from 'styled-components'
import { FC } from 'react'
import { BidTypes } from 'modules/domain/bid/types'
import { useDealContext } from '../DealContext'
import { useDealPartyChange } from '../../hooks/useDealPartyChange'
import { useMediaQuery } from 'react-responsive'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { MobileBids } from './MobileBids'

const BidDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 495px);
  column-gap: 16px;
`
export const Bids: FC = () => {
  const { deal } = useDealContext()
  const partyChanger = useDealPartyChange({ deal })

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      {isMobile ? (
        <MobileBids partyChanger={partyChanger} />
      ) : (
        <BidDetailsWrapper>
          {[BidTypes.sale, BidTypes.purchase].map((bidType) => (
            <BidDetailBlock key={bidType} bidType={bidType} partyChanger={partyChanger} />
          ))}
        </BidDetailsWrapper>
      )}
    </>
  )
}
