import { DealPartyChangeData } from '../../hooks/useDealPartyChange'
import { AAntdTabs } from 'views/components/Analytics'
import { BidTypes } from 'modules/domain/bid/types'
import { BidDetailBlock } from '../BidDetailBlock'
import { useTranslation } from 'react-i18next'
import { FC, useMemo, useState } from 'react'
import styled from 'styled-components'
import { defaultTheme } from '@agro-club/agroclub-shared'

const Wrapper = styled.div`
  background: ${defaultTheme.color.backgroundSecondary};
  border-radius: 16px;
  padding-top: 28px;
  .ant-tabs-nav {
    padding: 0 16px;
  }
`

export const MobileBids: FC<{ partyChanger: DealPartyChangeData }> = ({ partyChanger }) => {
  const { t } = useTranslation('deal')
  const [activeTab, setActiveTab] = useState<string>()

  const tabItems = useMemo(() => {
    const initialTabsData = {
      [BidTypes.sale]: {
        label: t('seller'),
      },
      [BidTypes.purchase]: {
        label: t('buyer'),
      },
    }

    const tabs: any = []
    for (const key of Object.keys(initialTabsData)) {
      tabs.push({
        label: initialTabsData[key].label,
        key,
        children: <BidDetailBlock key={key} bidType={BidTypes[key]} partyChanger={partyChanger} />,
      })
    }
    return tabs
  }, [t, partyChanger])

  return (
    <Wrapper>
      <AAntdTabs id="bidTabs" items={tabItems} onChange={(tab) => setActiveTab(tab)} activeKey={activeTab} />
    </Wrapper>
  )
}
