import { BidTypes } from 'modules/domain/bid/types'
import { FC } from 'react'
import AddressLegalType from 'views/components/AddressLegalType'
import { AddressExtraInfo } from 'views/components/AddressTitle/AddressTitle'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { Card } from 'views/components/Card/Card'
import { isPurchaseBid } from 'modules/domain/bid/utils'
import styled from 'styled-components'
import { Typography, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { AddressKey, useAddressFormatter } from 'modules/domain/logisticsKR/utils'
import { isRussia } from 'env'
import { refetchFunc } from 'modules/domain/common/hooks'
import { Deal } from 'modules/domain/deal/types'
import { getDealBidAndContract } from 'modules/domain/deal/utils'
import { Address } from 'modules/domain/types'
import { WarehouseEditAction } from './BidDetailActions/WarehouseEditAction'

const WarehouseInfoWrapper = styled.div`
  display: flex;
  margin-right: 93px;
  color: ${defaultTheme.color.textPrimary900};
  ${Typography.bodyLarge};
`
const MarginBlock = styled.div`
  margin-top: 8px;
`

type Props = {
  deal: Deal
  bidType: BidTypes
  readOnly: boolean
  refetch: refetchFunc
}

export const BidDetailAddress: FC<Props> = ({ deal, bidType, readOnly = false, refetch }) => {
  const { bid, contract } = getDealBidAndContract(deal, bidType)
  const contractOrBid = contract ?? bid

  const { t } = useTranslation('deal')
  const formatter = useAddressFormatter()

  const address = contractOrBid?.address as Address
  const isPurchase = isPurchaseBid(contractOrBid)
  const addressLabel = isPurchase ? t('fields.unloading_address') : t('fields.loading_address')

  const addressKeys: AddressKey[] = isPurchase
    ? [
        'vehicle_type',
        'is_dump_truck',
        'unloading_type',
        'volume_per_day',
        'overload',
        'schedule',
        'works_on_weekend',
        'car_delivery',
        'railway_delivery',
      ]
    : [
        'gates_height',
        'loader_type',
        'scales_capacity',
        'scales_remoteness',
        'scales_length',
        'volume_per_day',
        'overload',
        'vehicle_type',
        'schedule',
        'works_on_weekend',
        'car_delivery',
        'railway_delivery',
      ]

  const addressWarehouseInfo = formatter(address, ...addressKeys).join(', ')

  return (
    <>
      <KeyValue label={addressLabel}>
        <AddressExtraInfo address={address} />
        <div>
          {address.address}
          <AddressLegalType address={address} />
        </div>
        {isRussia && (
          <MarginBlock>
            <Card.Container bordered disableShadow>
              <WarehouseInfoWrapper>{addressWarehouseInfo}</WarehouseInfoWrapper>
              <WarehouseEditAction disabled={readOnly} deal={deal} refetch={refetch} bidType={bidType} />
            </Card.Container>
          </MarginBlock>
        )}
      </KeyValue>
    </>
  )
}
