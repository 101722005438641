import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { Progress, useHelmet } from '@agro-club/agroclub-shared'

import ItemLoadingLayout from 'views/layouts/ItemLoadingLayout/ItemLoadingLayout'
import * as Layout from 'views/layouts/NewLayout'
import { CompanyExtraData as CompanyExtraDataComponent } from 'views/pages/Company/CompanyDetail/CompanyExtraData'
import * as Header from 'views/ui/Header/Header'
import CompanyRoutes from 'views/pages/Company/routes'
import { useSingleEntity } from 'modules/domain/common/hooks'
import { User } from 'modules/domain/user/types'
import { endpoints } from 'modules/endpoints'
import { CompanyGeneral } from './CompanyGeneral/CompanyGeneral'
import styled from 'styled-components'
import { Company, CompanyExtraData } from 'modules/domain/company/types'
import { CompanySubtitle } from './CompanySubtitle'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from '../analyticsPlaces'
import { AButton } from 'views/components/Analytics'
import { SectionsWrapper, TitleWrapper } from './styled'
import { FactoringStatuses } from './FactoringStatuses'
import { CompanyFactoring } from './CompanyFactoring/CompanyFactoring'

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 802px 802px;
  height: 100%;
  column-gap: 24px;
  overflow-y: auto;
`

export const CompanyDetail = () => {
  const { t } = useTranslation('company')
  const { id } = useParams()
  const [, extraData] = useSingleEntity<CompanyExtraData>(endpoints.companyExtraData(id), false)
  const [, companyUsers] = useSingleEntity<User[]>(endpoints.employee(id), false)
  const [fetchProgress, company, refreshCompany] = useSingleEntity<Company>(endpoints.company(id))

  useHelmet({ title: company?.name || '' })

  if (fetchProgress === Progress.WORK || !company) {
    return <ItemLoadingLayout id={id} />
  }

  const report = extraData?.kontur_brief_report?.uploaded_file

  return (
    <APageWrapper page={AnalyticsPages.COMPANY_DETAIL} place={AnalyticsPlaces.DETAIL} id={id}>
      <Layout.WrapperContentFlex>
        <Layout.Header>
          <Layout.TopHeader>
            <Header.Breadcrumbs
              routes={[
                { path: CompanyRoutes.List, breadcrumbName: t('list.heading') },
                { breadcrumbName: company.name },
              ]}
            />
            {report && (
              <AButton id="report" intent="secondary" size="medium" target="_blank" rel="noreferrer" href={report}>
                {t('common:downloadReport')}
              </AButton>
            )}
          </Layout.TopHeader>
          <Layout.PageTitleWrapper>
            <TitleWrapper>
              <Header.Title compact title={company.name} />
              <FactoringStatuses factoringStatus={company.factoring_request?.status} />
            </TitleWrapper>
            <CompanySubtitle company={company} />
          </Layout.PageTitleWrapper>
        </Layout.Header>

        <ContentWrapper>
          <SectionsWrapper>
            <CompanyFactoring company={company} refetchCompany={refreshCompany} />
            <CompanyGeneral refreshCompany={refreshCompany} company={company} companyUsers={companyUsers} />
          </SectionsWrapper>

          {!!extraData && <CompanyExtraDataComponent extraData={extraData} />}
        </ContentWrapper>
      </Layout.WrapperContentFlex>
    </APageWrapper>
  )
}
