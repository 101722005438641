import { FC } from 'react'

import { useSingleEntity } from 'modules/domain/common/hooks'
import { RespFile } from 'modules/domain/types'
import { endpoints } from 'modules/endpoints'
import { apiClient } from 'modules/utils/httpClient'
import AUploadFiles from 'views/components/UploadFiles/AUploadFiles'
import { useTranslation } from 'react-i18next'
import { useAProgress } from 'hooks/useAProgress'
import { Card } from 'views/components/Card/Card'

interface Props {
  id?: number
}

const TaskFiles: FC<Props> = ({ id }) => {
  const { t } = useTranslation('my_tasks')
  const [progress, files, refetch] = useSingleEntity<RespFile[]>(endpoints.myTasksFiles(id))

  const [, removeFile] = useAProgress((id, fileId: string) => apiClient.delete(endpoints.removeTasksFile(id, fileId)), {
    eventName: 'removeFile',
    onSuccess: refetch,
  })

  const handleAdd = async (file: File) => {
    const form = new FormData()
    form.append('uploaded_file', file)
    await apiClient.post(endpoints.myTasksFiles(id), form)
    refetch()
  }

  return (
    <Card.GapableContent>
      <Card.Title>{t('detail.documents')}</Card.Title>
      <AUploadFiles
        progress={progress}
        removeFile={(fileId) => removeFile(id, fileId)}
        addFile={({ file }) => handleAdd(file)}
        fileList={files}
      />
    </Card.GapableContent>
  )
}

export default TaskFiles
