import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnData } from 'modules/types'
import useFormatDate from 'hooks/useFormatDate'
import { generatePath } from 'react-router'
import UserRoutes from 'views/pages/User/routes'
import { Task } from 'modules/domain/task/types'
import DealRoutes from 'views/pages/Deal/routes'
import { DASH, NOT_AVAILABLE_URL } from 'modules/constants'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { UseDashboardColumnsProps } from '../components/DashboardTasksList'
import { ActionButtons } from 'views/components/Table/Cells/ActionButtons'
import { BlackListUserBtn } from 'views/components/TaskActions/BlackListUserBtn'
import APhone from 'views/ui/Phone/APhone'
import { isBrazil, isRussia, isUserProductTagsEnabled, REACT_APP_INSTANCE_ID } from 'env'
import { UserShort } from 'modules/domain/types'
import { TruncatedValue } from 'views/components/TruncatedValue/TruncatedValue'
import { CompleteTaskBtn } from 'views/components/TaskActions/CompleteTask/CompleteTaskBtn'
import { CompanySizeTag } from 'views/pages/User/UserDetail/CompanySizeTag'

const getPathname = (row: Task) => {
  const userId = row?.linked_user?.id
  const dealId = row?.linked_deal?.id

  if (userId) {
    return generatePath(UserRoutes.Details, { id: userId })
  }

  if (dealId) {
    return generatePath(DealRoutes.Details, { id: dealId })
  }

  return NOT_AVAILABLE_URL
}

const defaultWidthOpt = {
  deadline: 150,
  status: 150,
  subject: 340,
  product_tags: 262,
  actions: 164,
  linked_user: 280,
  phone: 190,
  assignee: 172,
  assignee_team: 112,
}

const widthOptions = {
  br: {
    deadline: 140,
    subject: 468,
  },
  us: {
    subject: 280,
    product_tags: 234,
    actions: 128,
    phone: 190,
  },
}

const overrideWidth = widthOptions[REACT_APP_INSTANCE_ID] || {}

const columnsWidth = { ...defaultWidthOpt, ...overrideWidth }

export const useCallsColumns = ({ successText, refetch }: UseDashboardColumnsProps): ColumnData[] => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: t('dashboard:callBack'),
        dataIndex: 'deadline',
        width: columnsWidth.deadline,
        sorter: true,
        render: (deadline) => <>{formatDate(deadline, true)}</>,
      },
      ...(isRussia
        ? [
            {
              title: t('dashboard:status'),
              dataIndex: 'linked_user',
              width: columnsWidth.status,
              render: (linked_user) =>
                linked_user?.company_size ? <CompanySizeTag companySize={linked_user.company_size} /> : DASH,
            },
          ]
        : []),

      {
        title: t('task:list.tableHeaders.subject'),
        dataIndex: 'subject',
        width: columnsWidth.subject,
        render: (subject) => <TruncatedValue hint={subject}>{subject}</TruncatedValue>,
      },
      {
        title: t('common:user'),
        dataIndex: 'linked_user',
        autoWidth: columnsWidth.linked_user,
        render: (linked_user, record) => {
          // it is important to have state here for Task modal in Users
          const { pathname, search } = window.location
          return linked_user ? (
            <TruncatedValue hint={linked_user?.full_name}>
              <TableTextLink
                id="user"
                tableItemId={linked_user?.id}
                state={{ pathname, search }}
                to={getPathname(record)}
              >
                {linked_user?.full_name}
              </TableTextLink>
            </TruncatedValue>
          ) : (
            DASH
          )
        },
      },
      {
        title: t('common:phone'),
        width: columnsWidth.phone,
        dataIndex: 'linked_user',
        render: (linked_user: UserShort) =>
          linked_user ? (
            <APhone phone={linked_user.phone} shortBtnTitle isMobileShort={linked_user.is_mobile_phone} />
          ) : (
            DASH
          ),
      },
      ...(isUserProductTagsEnabled
        ? [
            {
              title: t('user:list.tableHeaders.product_tags'),
              width: columnsWidth.product_tags,
              dataIndex: 'linked_user',
              key: 'product_tags',
              render: (linked_user?: UserShort) => linked_user?.product_tags?.map((t) => t.title).join(', '),
            },
          ]
        : []),
      {
        title: t('task:list.tableHeaders.assignee'),
        dataIndex: 'assignee',
        width: columnsWidth.assignee,
        render: (assignee) => assignee && <>{assignee.short_name}</>,
      },
      ...(isRussia
        ? [
            {
              title: t('task:list.tableHeaders.team'),
              width: columnsWidth.assignee_team,
              dataIndex: 'assignee_team',
              render: (assignee_team) => assignee_team && <>{assignee_team.name}</>,
            },
          ]
        : []),
      {
        title: t('task:list.tableHeaders.action'),
        width: columnsWidth.actions,
        dataIndex: 'id',
        render: (id, task) => {
          return (
            <ActionButtons>
              <CompleteTaskBtn refetch={refetch} task={task} successText={successText} forDashboard />
              {/* temporarily hide for Brazil*/}
              {!isBrazil && <BlackListUserBtn refetch={refetch} userId={task?.linked_user?.id} forDashboard />}
            </ActionButtons>
          )
        },
      },
    ],
    // make sure that we have latest refetch func
    [t, formatDate, successText, refetch],
  )
}
