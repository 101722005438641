import { useTranslation } from 'react-i18next'

import { Typography } from '@agro-club/agroclub-shared'
import styled from 'styled-components'

import { CompanyDadata } from 'modules/domain/collection/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { getPersonalNumberLabel } from 'modules/utils/numbers/formatPersonalNumber'
import { FC } from 'react'

const StyledCompany = styled.div`
  margin-top: 4px;
  margin-right: 26px;
  ${Typography.bodyRegular}
`

type Props = {
  company?: CompanyDadata
}

export const CompanyShortInfo: FC<Props> = (props) => {
  const { company } = props
  const { t } = useTranslation('company')

  if (!company) {
    return null
  }

  return (
    <StyledCompany>
      <KeyValue horizontal label={getPersonalNumberLabel()}>
        {company.inn || t('common:notSpecified')}
      </KeyValue>
      {company.address_short && (
        <KeyValue horizontal label={t('form.fields.legalAddress')}>
          {company.address_short}
        </KeyValue>
      )}
    </StyledCompany>
  )
}
