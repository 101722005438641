import { isBidProductParamsEnabled, isFuturesEnabled, isUS } from 'env'
import { EXPANDABLE_COLUMN_WIDTH } from 'modules/constants'
import { ColumnData } from 'modules/types'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import { labelTarif } from 'modules/utils/numbers/formatTarif'
import { useTranslation } from 'react-i18next'
import { DatePeriodFormatted } from 'views/components/DatePeriodFormatted'
import * as renderers from 'views/components/Table/renderers'
import formatNumber from 'modules/utils/numbers/formatNumber'
import PriceCell from './PriceCell'
import ProductCell from './ProductCell'
import SwitchCell from './SwitchCell'
import { isBidWithPricePeriods } from 'modules/domain/bid/utils'
import { BidKind } from 'modules/domain/bid/types'
import { useState } from 'react'
import { USPriceCell } from 'views/pages/User/UserBidPrice/BidPricesTable/USPriceCell'

const useColumns = ({ refetch, nested }): ColumnData[] => {
  const { t } = useTranslation('user')
  const [disabledRowInputsIds, setDisabledRowInputsIds] = useState<number[]>([])

  return [
    nested
      ? {
          width: EXPANDABLE_COLUMN_WIDTH,
          render: () => ' ',
        }
      : null,
    {
      title: t('list.tableHeadersBidPrice.created'),
      width: 100,
      dataIndex: 'created',
      render: renderers.date,
    },
    {
      title: t('list.tableHeadersBidPrice.modified'),
      width: 100,
      dataIndex: 'modified',
      render: renderers.date,
    },
    nested
      ? {
          width: isBidProductParamsEnabled ? 350 : 100,
          render: () => ' ',
        }
      : {
          title: t('list.tableHeadersBidPrice.product'),
          width: isBidProductParamsEnabled ? 350 : 100,
          dataIndex: 'product',
          render: (_value, row) => <ProductCell {...row} />,
        },
    {
      title: t('common:address'),
      autoWidth: isFuturesEnabled ? 308 : 508,
      dataIndex: 'address',
      render: (value) => (nested ? ' ' : value?.address),
    },
    isFuturesEnabled
      ? {
          title: t('common:period'),
          width: 200,
          sorter: false,
          render: (_, period) => <DatePeriodFormatted period={period} />,
        }
      : null,
    {
      title: labelQuantity(),
      width: 120,
      dataIndex: 'quantity',
      align: 'right',
      render: (value) => formatNumber(value),
    },
    ...(isUS
      ? [
          {
            title: t('bid:form.contractType'),
            width: 120,
            dataIndex: 'contract_type',
            render: (_, bid) => t(`bid:kind.${bid.contract_type}`),
          },
          {
            title: t('bid:symbol'),
            width: 100,
            dataIndex: 'symbol',
          },
          {
            title: labelTarif(t('bid:form.basis')),
            width: 120,
            dataIndex: 'basis',
            render: (_, bid) => (
              <USPriceCell
                rowsToUpdate={disabledRowInputsIds}
                setRowsToUpdate={setDisabledRowInputsIds}
                fieldName={BidKind.basis}
                refetch={refetch}
                currentValue={bid.basis}
                bid={bid}
              />
            ),
          },
          {
            title: labelTarif(t('bid:form.futures')),
            width: 120,
            dataIndex: 'futures_price',
            render: (_, bid) => (
              <USPriceCell
                rowsToUpdate={disabledRowInputsIds}
                setRowsToUpdate={setDisabledRowInputsIds}
                fieldName={BidKind.futures}
                refetch={refetch}
                currentValue={bid.futures_price}
                bid={bid}
              />
            ),
          },
          {
            title: labelTarif(t('common:price.default')),
            width: 120,
            dataIndex: 'price',
            render: (_, bid) => (
              <USPriceCell
                rowsToUpdate={disabledRowInputsIds}
                setRowsToUpdate={setDisabledRowInputsIds}
                fieldName={BidKind.cash_priced}
                refetch={refetch}
                currentValue={bid.price}
                bid={bid}
              />
            ),
          },
        ]
      : [
          {
            title: labelTarif(t('common:price.default')),
            width: 120,
            dataIndex: 'price',
            render: (_, bid) => <PriceCell refetch={refetch} currentValue={bid.price} bid={bid} />,
          },
        ]),
    {
      title: t('list.tableHeadersBidPrice.publish'),
      align: 'left',
      width: 120,
      render: (_, bid) => {
        if (isBidWithPricePeriods(bid)) {
          return ' '
        }
        return <SwitchCell bid={bid} refetch={refetch} />
      },
    },
  ].filter(Boolean) as ColumnData[]
}

export default useColumns
