import { Icons, defaultTheme, useHelmet } from '@agro-club/agroclub-shared'
import APageWrapper from 'views/components/PageWrapper/APageWrapper'
import { AnalyticsPages, AnalyticsPlaces } from './analyticsPlaces'
import { RematchModal } from './Rematch/RematchModal/RematchModal'
import { AAntdTabs, AButton } from 'views/components/Analytics'
import { FC, useContext, useEffect, useState } from 'react'
import { AuthContext } from 'modules/context/AuthContext'
import { DealCondition } from 'modules/domain/deal/types'
import { ListRequestParams } from 'modules/domain/types'
import { useAnalyticsContext } from 'analytics/hooks'
import { getMonthList } from 'modules/utils/helpers'
import { DealFilters } from './Filters/DealFilters'
import * as Layout from 'views/layouts/NewLayout'
import * as Header from 'views/ui/Header/Header'
import { useMediaQuery } from 'react-responsive'
import { useTranslation } from 'react-i18next'
import { YEAR_MONTH } from 'modules/constants'
import { useFilters } from 'hooks/useFilter'
import { generatePath } from 'react-router'
import { isBidContractsEnabled } from 'env'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'
import { Board } from './Board/Board'
import { format } from 'date-fns'
import DealRoutes from './routes'

export const defaultListRequestParams: ListRequestParams = {
  page: 1,
  filter: {},
  pageSize: 10000,
}

export const DealList: FC = () => {
  const { t } = useTranslation('deal')
  const { setOptions } = useAnalyticsContext()
  const [showModal, setShowModal] = useState(false)
  useHelmet({ title: t('title') })
  const navigate = useNavigate()
  const { profile: currentUser } = useContext(AuthContext)

  const { activeTab: activeTabParams, selectedDeal: selectedDealParams } = useParams()
  const [activeTab, setActiveTab] = useState<string>(activeTabParams || DealCondition.open)

  const [listRequestParams, setListRequestParams] = useState<ListRequestParams>({
    ...defaultListRequestParams,
  })

  const defaultFilterValues = {
    ...(currentUser?.profile?.team?.id && { team: String(currentUser.profile.team.id) }),
    month: format(getMonthList()[0], YEAR_MONTH),
  }

  const { isFiltersLoaded, updateFilterState } = useFilters({
    listRequestParams,
    setListRequestParams,
    defaultParams: defaultFilterValues,
  })

  const changeRoute = () => {
    navigate(
      {
        pathname: generatePath(DealRoutes.ListWithTab, {
          activeTab: activeTab,
        }),
        search: location.search,
      },
      { replace: true },
    )
    setOptions({
      place: activeTab,
    })
  }

  useEffect(() => {
    if (selectedDealParams) return
    changeRoute()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab])

  const changeActiveTab = (type) => {
    setActiveTab(type)

    // wee need to reset selected tab when tab is changed
    if (selectedDealParams) {
      changeRoute()
    }
  }

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <APageWrapper page={AnalyticsPages.DEALS} place={AnalyticsPlaces.LIST}>
      <Layout.WrapperContent>
        <Layout.Header>
          {!isMobile && (
            <Layout.TopHeader>
              <Header.Breadcrumbs routes={[{ breadcrumbName: t('title') }]} />
              {isBidContractsEnabled && (
                <AButton id="add" intent="approve" Icon={Icons.IconAdd} onClick={() => setShowModal(true)}>
                  {t('common:add')}
                </AButton>
              )}
            </Layout.TopHeader>
          )}

          <Layout.PageName>{t('title')}</Layout.PageName>

          <DealFilters
            defaultFilterValues={defaultFilterValues}
            setListRequestParams={setListRequestParams}
            updateFilterState={updateFilterState}
            listRequestParams={listRequestParams}
            isFiltersLoaded={isFiltersLoaded}
          />
        </Layout.Header>
        <AAntdTabs
          id="tabs"
          items={[
            {
              label: t('dealCondition.open'),
              key: DealCondition.open,
              children: (
                <Board
                  listRequestParams={listRequestParams}
                  isFiltersLoaded={isFiltersLoaded}
                  typeDeals={DealCondition.open}
                  activeTab={activeTab}
                />
              ),
            },
            {
              label: t('dealCondition.closed'),
              key: DealCondition.closed,
              children: (
                <Board
                  listRequestParams={listRequestParams}
                  isFiltersLoaded={isFiltersLoaded}
                  typeDeals={DealCondition.closed}
                  activeTab={activeTab}
                />
              ),
            },
          ]}
          onChange={changeActiveTab}
          activeKey={activeTab}
        />
      </Layout.WrapperContent>
      {showModal && <RematchModal onClose={() => setShowModal(false)} />}
    </APageWrapper>
  )
}
