import formatPrice, { labelPrice } from 'modules/utils/numbers/formatPrice'
import { CompanyLink } from 'views/components/CompanyLink/CompanyLink'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { labelQuantity } from 'modules/utils/numbers/formatQuantity'
import formatNumber from 'modules/utils/numbers/formatNumber'
import { ColumnsType } from '@agro-club/agroclub-shared'
import { Export } from 'modules/domain/export/types'
import DealRoutes from 'views/pages/Deal/routes'
import UserRoutes from 'views/pages/User/routes'
import { useTranslation } from 'react-i18next'
import { generatePath } from 'react-router'
import { useMemo } from 'react'

const useColumns = (): ColumnsType<Export> => {
  const { t } = useTranslation('export')

  return useMemo(
    () => [
      {
        title: t('deal'),
        dataIndex: 'id',
        width: 100,
        render: (id) => (
          <TableTextLink id="deal" tableItemId={id} to={generatePath(DealRoutes.Details, { id })}>
            {`ID ${id}`}
          </TableTextLink>
        ),
      },
      {
        title: t('sellerCompany'),
        dataIndex: 'company',
        width: 244,
        render: (company) => <CompanyLink bodysize="bodyMedium" company={company} />,
      },
      {
        title: t('seller'),
        dataIndex: 'owner',
        width: 246,
        render: (owner) => (
          <TableTextLink id="deal" tableItemId={owner.id} to={generatePath(UserRoutes.Details, { id: owner.id })}>
            {owner.full_name}
          </TableTextLink>
        ),
      },
      {
        title: labelQuantity(t('volume')),
        dataIndex: 'quantity',
        width: 82,
        render: (quantity) => formatNumber(quantity),
      },
      {
        title: labelQuantity(t('uploaded')),
        dataIndex: 'loaded_quantity',
        width: 114,
        render: (loaded_quantity) => formatNumber(loaded_quantity),
      },
      {
        title: labelQuantity(t('delivered')),
        dataIndex: 'delivered_quantity',
        width: 90,
        render: (delivered_quantity) => formatNumber(delivered_quantity),
      },
      {
        title: labelQuantity(t('remaining')),
        dataIndex: 'remaining_quantity',
        width: 98,
        render: (remaining_quantity) => formatNumber(remaining_quantity),
      },
      {
        title: labelPrice(t('price')),
        dataIndex: 'price_exw',
        width: 74,
        render: (price_exw) => formatPrice(price_exw),
      },
    ],
    [t],
  )
}

export default useColumns
