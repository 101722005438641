import { usePermissions } from 'views/components/PermissionCheck/usePermissions'
import { FC, ReactElement } from 'react'

interface Props {
  scope: string[]
  children: ReactElement
}

const PermissionCheck: FC<Props> = ({ scope, children }) => {
  const available = usePermissions(scope)

  return available ? children : null
}

export default PermissionCheck
