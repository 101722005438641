import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ColumnData } from 'modules/types'
import useFormatDate from 'hooks/useFormatDate'
import { generatePath } from 'react-router'
import UserRoutes from 'views/pages/User/routes'
import { TableTextLink } from 'views/ui/TableTextLink/TableTextLink'
import { UseDashboardColumnsProps } from '../components/DashboardTasksList'
import { ActionButtons } from 'views/components/Table/Cells/ActionButtons'
import { BlackListUserBtn } from 'views/components/TaskActions/BlackListUserBtn'

import APhone from 'views/ui/Phone/APhone'
import { CompleteTaskBtn } from 'views/components/TaskActions/CompleteTask/CompleteTaskBtn'

export const useNewUserColumns = ({ refetch, successText }: UseDashboardColumnsProps): ColumnData[] => {
  const { t } = useTranslation()
  const formatDate = useFormatDate()

  return useMemo(
    () => [
      {
        title: t('newUsers:list.tableHeaders.date_joined'),
        width: 140,
        dataIndex: 'registration_date',
        render: (registration_date) => <>{formatDate(registration_date, true)}</>,
      },
      {
        title: t('newUsers:list.tableHeaders.user'),
        dataIndex: 'linked_user',
        autoWidth: 352,
        render: ({ full_name }, record) => {
          const { pathname, search } = window.location
          return (
            <TableTextLink
              id="user"
              tableItemId={record.linked_user.id}
              state={{ pathname, search }}
              to={generatePath(UserRoutes.Details, { id: record.linked_user.id })}
            >
              {full_name}
            </TableTextLink>
          )
        },
      },
      {
        title: t('common:phone'),
        dataIndex: 'linked_user',
        width: 190,
        render: ({ phone }) => <APhone phone={phone} shortBtnTitle />,
      },
      {
        title: t('newUsers:list.tableHeaders.user_type'),
        dataIndex: 'linked_user',
        key: 'user_type',
        width: 334,
        render: ({ profile_type }) => <>{t(`newUsers:list.profile_type.${profile_type}`)}</>,
      },
      {
        title: t('newUsers:list.tableHeaders.team'),
        width: 112,
        dataIndex: 'assigned_team',
        render: (assigned_team) => assigned_team && <>{assigned_team.name}</>,
      },
      {
        title: t('newUsers:list.tableHeaders.action'),
        width: 180,
        dataIndex: 'id',
        render: (id, task) => {
          return (
            <ActionButtons>
              <CompleteTaskBtn refetch={refetch} task={task} successText={successText} forDashboard />
              <BlackListUserBtn refetch={refetch} userId={task.linked_user.id} forDashboard />
            </ActionButtons>
          )
        },
      },
    ],
    [formatDate, refetch, successText, t],
  )
}
