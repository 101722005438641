import { Text, TextColor } from '@agro-club/agroclub-shared'
import { FC, ReactNode } from 'react'

type Props = {
  text: ReactNode
  subText: ReactNode
  subColor?: TextColor
}

export const TextWithSubText: FC<Props> = ({ text, subText, subColor = 'primary' }) => {
  return (
    <div>
      <Text>{text}</Text>
      <Text color={subColor} typography="bodySmall">
        {subText}
      </Text>
    </div>
  )
}
