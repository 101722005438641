import { ExecutorsModalControl } from 'views/pages/Deal/DealDetail/DealEditModals/ExecutorsModal/ExecutorsModalControl'
import HorizontalKeyValue from 'views/components/KeyValue/HorizontalKeyValue'
import { Deal, DealStatusTypes } from 'modules/domain/deal/types'
import { KeyValue } from 'views/components/KeyValue/KeyValue'
import { defaultTheme } from '@agro-club/agroclub-shared'
import { Card } from 'views/components/Card/Card'
import { useMediaQuery } from 'react-responsive'
import { useDealContext } from '../DealContext'
import { useTranslation } from 'react-i18next'
import { Logisticians } from './Logisticians'
import { isBrazil, isRussia } from 'env'
import { Regionals } from './Regionals'
import { FC, useState } from 'react'
import styled from 'styled-components'

const ExecutorsList: FC<{ deal: Deal }> = ({ deal }) => {
  const { t } = useTranslation('executors')
  const getName = (data) => `${data.full_name}${data.is_active ? '' : t('common:deactivated')}`

  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  const Component = isMobile ? KeyValue : HorizontalKeyValue

  return (
    <>
      <Component label={t(`user_coordinator`)} columnGap="medium" labelTypo="bodyMedium" valueTypo="bodyLarge">
        {deal.executors?.user_coordinator ? getName(deal.executors.user_coordinator) : t('not_selected')}
      </Component>

      <Logisticians deal={deal} />

      <Component label={t(`deal_coordinator`)} columnGap="medium" labelTypo="bodyMedium" valueTypo="bodyLarge">
        {deal.executors?.deal_coordinator ? getName(deal.executors.deal_coordinator) : t('not_selected')}
      </Component>

      <Component label={t(`trader`)} columnGap="medium" labelTypo="bodyMedium" valueTypo="bodyLarge">
        {deal.executors?.trader ? getName(deal.executors.trader) : t('not_selected')}
      </Component>

      {!isBrazil && (
        <Component label={t(`shipment_coordinator`)} columnGap="medium" labelTypo="bodyMedium" valueTypo="bodyLarge">
          {deal.executors?.shipment_coordinator ? getName(deal.executors.shipment_coordinator) : t('not_selected')}
        </Component>
      )}

      {isRussia && <Regionals deal={deal} />}
    </>
  )
}

const StyledCardContainer = styled(Card.Container)`
  @media (max-width: ${defaultTheme.queries.mobile}) {
    border-radius: 16px;
  }
`

export const DealExecutorsBlock: FC = () => {
  const { deal } = useDealContext()
  const [editModalIsOpen, setEditModalIsOpen] = useState(false)
  if (deal.status == DealStatusTypes.DRAFT) {
    return null
  }

  return (
    <StyledCardContainer bordered disableShadow padding="compact">
      <Card.GapableContent>
        <Card.Grid cols={2} rowGap={4} columnGap={4}>
          <ExecutorsList deal={deal} />
        </Card.Grid>

        {editModalIsOpen && <ExecutorsModalControl onClose={() => setEditModalIsOpen(false)} />}
      </Card.GapableContent>
      <Card.EditButton id="editExecutors" onClick={() => setEditModalIsOpen(true)} />
    </StyledCardContainer>
  )
}
