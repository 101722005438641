import { FC, useMemo } from 'react'
import { SharedModalInputs } from 'views/components/SharedModal/styled'
import { ShortFormControl } from 'views/styled/common'
import FormSelectShared from 'views/components/FormSelectShared'
import { endpoints } from 'modules/endpoints'
import { FormGroup, Input, TextArea, defaultTheme } from '@agro-club/agroclub-shared'
import { useTranslation } from 'react-i18next'
import { DateTimePicker } from 'views/components/DateTimePicker/DateTimePicker'
import { AssigneesWrapper } from 'views/pages/MyTasks/styled'
import { FormGroupUpload } from '../FormGroupUpload/FormGroupUpload'
import { FormikFieldsType } from 'modules/domain/types'
import { useMediaQuery } from 'react-responsive'

type Props = {
  formik: FormikFieldsType
  isMyTasks?: boolean
}

export const TaskModalsControls: FC<Props> = ({ formik, isMyTasks }) => {
  const { t } = useTranslation('task')

  const filterForAssignee = useMemo(
    () => (formik.values.assigned_team ? { team: formik.values.assigned_team } : null),
    [formik.values.assigned_team],
  )
  const isMobile = useMediaQuery({
    query: `(max-width: ${defaultTheme.queries.mobile})`,
  })

  return (
    <>
      <SharedModalInputs>
        <FormSelectShared
          label={t('my_tasks:modal:type_task')}
          endpoint={endpoints.typesTask(isMyTasks ? 'my-tasks' : 'manual-tasks')}
          fieldName="task_type"
          formik={formik}
          simpleApiFormat
          getLabel={(dto) => dto.label}
          showSearch={false}
          required
        />
        <FormGroup label={t('form.subject')} error={formik.errors.subject} required>
          <Input {...formik.getFieldProps('subject')} invalid={!!formik.errors.subject} />
        </FormGroup>
        <ShortFormControl>
          <FormGroup label={t('form.deadline')} error={formik.errors.deadline} required>
            <DateTimePicker
              value={formik.values.deadline}
              onChange={(value) => formik.setFieldValue('deadline', value['date'])}
              allowClear
            />
          </FormGroup>
          <FormSelectShared
            endpoint={endpoints.priorityTask()}
            getLabel={(dto) => dto.label}
            label={t('my_tasks:modal.priority')}
            placeholder=""
            fieldName="priority"
            showSearch={false}
            formik={formik}
            simpleApiFormat
          />
          <FormSelectShared
            label={t('common:team')}
            formik={formik}
            onChange={() => formik.setFieldValue('potential_executors', [])}
            endpoint={endpoints.tasksTeamsChoices()}
            simpleApiFormat
            getLabel={(dto) => dto.label}
            fieldName="assigned_team"
            showSearch={false}
            placeholder=""
          />
        </ShortFormControl>
        <AssigneesWrapper>
          <FormSelectShared
            label={t('form.potential_executors')}
            formik={formik}
            endpoint={endpoints.taskAssignees()}
            filter={filterForAssignee}
            fieldName="potential_executors"
            simpleApiFormat
            getLabel={(dto) => dto.full_name}
            searchField="search"
            required
            multiple
          />
        </AssigneesWrapper>
        <FormGroup error={formik.errors.comment} label={t('form.comment')}>
          <TextArea
            className="comment-area"
            {...formik.getFieldProps('comment')}
            invalid={!!formik.errors.comment}
            height={isMobile ? 68 : 200}
          />
        </FormGroup>
        {isMyTasks && (
          <FormGroupUpload
            label={t('my_tasks:modal.files')}
            maxWidthFileUpload={523}
            fieldName="files"
            formik={formik}
          />
        )}
      </SharedModalInputs>
    </>
  )
}
