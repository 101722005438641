import { Card } from 'views/components/Card/Card'

import { Company } from 'modules/domain/company/types'
import { useTranslation } from 'react-i18next'
import { refetchFunc } from 'modules/domain/common/hooks'
import { RequestFactoring } from './RequestFactoring'
import { CompanyFactoringFiles } from './CompanyFactoringFiles'
import { isFactoringAvailable } from 'env'
import { CardRow } from 'views/pages/Company/CompanyDetail/styled'

interface Props {
  company: Company
  refetchCompany: refetchFunc
}

export const CompanyFactoring = ({ company, refetchCompany }: Props) => {
  const { t } = useTranslation('company')

  if (!isFactoringAvailable) return null

  return (
    <Card.Container>
      <Card.GapableContent>
        <CardRow>
          <Card.Title>{t('factoring')}</Card.Title>
          <RequestFactoring company={company} refetchCompany={refetchCompany} />
        </CardRow>
        <CompanyFactoringFiles company={company} refetchCompany={refetchCompany} />
      </Card.GapableContent>
    </Card.Container>
  )
}
